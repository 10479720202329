body {
  margin: 0;
  font-family: "Poppins";
	min-height:100vh;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
}

code {
  font-family: 'Poppins';
}

h1 {
  color: #88B04B;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-weight: 900;
  font-size: 40px;
  margin-bottom: 10px;
}

.blink_text {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.payment-card-content {
  color: #404F5E;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  margin: 0;
}

.checkmark {
  color: #9ABC66;
  font-size: 100px;
  line-height: 200px;
  margin-left: -15px;
}

.payment-card {
  background: white;
  padding: 60px;
  border-radius: 4px;
  box-shadow: 0 2px 3px #C8D0D8;
  display: inline-block;
  margin: 0 auto;
}

.ant-checkbox-wrapper {
  float: left;
}
.ant-modal-title {
  font-size: 20px;
  text-align: center;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  color: #283995;
}
.progress {
  box-shadow: none;
  border-radius: 5px;
}
.input-group-addon-eye {
  padding: 0 10px;
    background: #c4c3c3;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    cursor: pointer;
}

.font14 {
  font-size: 1.6em !important;
}

.progress-bar-danger {
  background-color: #e90f10;
}
.progress-bar-warning{
  background-color: #ffad00;
}
.progress-bar-success{
  background-color: #02b502;
}

.mobile-recharge .content-header {
  padding: 0 auto !important;
}

.content-header .font-merriweather {
  font-size: 22px;
  /* font-family: 'Merriweather', serif; */
}

.content-header {
  padding: 5px 15px !important;
}

.main-header .header-top-links .nav-link {
  height: auto;
  padding: .2rem 1rem;
}

.main-header.navbar {
  padding: 0px;
}

.content {
  padding: 20px;
}

.spin-loader {
  padding: 400px 0;
}

button,
span {
  display: inline-flex;
  align-items: center;
}
.dashboard-table div {
  border-bottom: 1px solid;
  border-color: #e8e7e3;
}

.header {
  padding: 10px;
  background: #edf2f4;
  border-bottom: 1px solid #999;
}

.header a {
  color: #0072ff;
  text-decoration: none;
  margin-left: 20px;
  margin-right: 5px;
}

.header a:hover {
  color: #8a0f53;
}

.header small {
  color: #666;
}

.header .active {
  color: #2c7613;
}

.swal2-icon {
  width: 8em !important;
  height: 8em !important;
}

.swal2-title {
  font-size: 2.875em !important;
}

.swal2-content {
  font-size: 1.5em !important;
}

.swal2-cancel {
  margin-right: 5px !important;
}

.swal2-popup {
  width: 478px !important;
}

.navbar-nav {
  font-size: 14px;
}

.dropdown-item {
  font-size: 14px;
}

.dropdown-menu {
  min-width: 12rem;
}

.main-menu {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 15px;
}

/****** login *****/

/* .navbar-light{
  background-color: #f2f2f2;
} */

.FooterWrapper {
  /* position: fixed; */
  bottom: 0;
  width: 100%;
}

.content-wrapper>.content-dashboard {
  padding: 0 5rem;

}

.content-wrapper {
  background: #ffffff;
}

.content-wrapper-inner {
  background: #DCDCDC;
}

.main-header {
  border-bottom: 0px;
  position: inherit;
}

.main-footer {
  background: #000000;
  font-size: 13px;
  color: #ffffff;
  margin-top: -1px;
}

.main-footer a {
  color: #ffffff;
}

.main-footer a:hover {
  color: #ffffff;
}

.helplineno {
  font-weight: 600;
  color: #333 !important;
}

.call-icon {
  color: #E31E24;
}

.helpline-text {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
}

.social-media .nav-item {
  width: 30px;
  height: 30px;
  line-height: 28px;
  text-align: center;
  border: 1px solid #999;
  margin-right: 10px !important;
  border-radius: 50%;
}

.social-media a {
  display: block;
}

.social-media .fa-facebook-f {
  color: #435995;
}

.social-media .fa-youtube {
  color: #db3131;
}

.footer-social-media {
  display: inline-flex;
  list-style: none;
  margin: 0px;
  padding: 0px 0;
}

.footer-social-media .nav-item {
  width: 30px;
  height: 30px;
  line-height: 28px;
  text-align: center;
  border: 1px solid #999;
  margin: 0 5px;
  border-radius: 50%;
}

.footer-social-media a {
  display: block;
}


.main-footer {
  border-top: 0px;
}

.login-outer {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff !important;
}

.login-container {
  max-width: 1400px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.login-outer {
  padding: 30px 0;
}

.login-box {
  width: 100%;
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
}

.login-img {
  text-align: center;
  padding-right: 100px;
}

.login-img img {
  max-width: 540px;
}

.login-box-heading {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 34px;

  /* identical to box height */
  color: #484848;
}

.agreeTerms {
  font-weight: 600 !important;
}

.login-button,
.login-button:hover {
  background: #E31E24;
  box-shadow: inset 2px 4px 8px rgba(0, 0, 0, 0.32);
  border-radius: 25px;
  color: #ffffff;
}

.forgot a {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #A8A8A8;
}

.member {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #A8A8A8;
}

.joinnow-text {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #E31E24;
  cursor: pointer;
}

.join-button {
  background: #2E26C6;
  box-shadow: inset 2px 4px 8px rgba(64, 62, 103, 0.6);
  border-radius: 25px;
  color: #ffffff;
}

/* .input-group-text{
  background: #fff;
 }
 .input-group-prepend{
  z-index: 4;
 }
 .input-group-prepend .input-group-text{
  border-right: 0px;
  padding-right: 5px;
  margin-right: -1px;
 } */
.cursor-pointer {
  cursor: pointer;
}

.group-append {
  position: absolute;
  top: 0px;
  right: 0px;
  padding: .375rem .75rem;
  color: #A8A8A8;
  z-index: 99;
}

/* .input-group-append .input-group-text{
  border-left: 0px;
  padding-left: 5px;
  margin-left: -1px;
  z-index: 4;
 } */



.social-media-circle {
  border: 1px solid #333333;
  padding: 5px 10px;
  border-radius: 50px;
  border-color: #fff;

}

.login-addImage {
  /* position: absolute; */
  width: 450px;
  height: 365px;
  padding: 170px 180px 0px 0px;
}

.registration-process {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 45px;
  text-align: center;
  color: #323232;
}


.inputs input {
  width: 60px;
  height: 60px;
  font-size: 40px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0
}

.card-2 {
  background-color: #fff;
  padding: 10px;
  width: 350px;
  height: 100px;
  bottom: -50px;
  left: 20px;
  position: absolute;
  border-radius: 5px
}

.card-2 .content {
  margin-top: 50px
}

.card-2 .content a {
  color: red
}

.form-control:focus {
  box-shadow: none;
  border-color: red;
}

.validate {
  border-radius: 20px;
  height: 40px;
  background-color: red;
  border: 1px solid red;
  width: 140px
}


.your-registration-is-successfull p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
}

.dear-partner-welcome-to-srpay {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 42px;
  color: #2E26C6;
}

.form-text {
  font-size: 14px;
}

.continue {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  cursor: pointer;
  /* identical to box height */

  text-align: center;
  text-decoration-line: underline;

  color: #E31E24;
}

.msg1 {
  line-height: 18px;
}

.pointer {
  cursor: pointer;
}

.otp-justify div {
  justify-content: space-around !important;
}

/****/

.content-wrapper {
  background: #DCDCDC;
}

.top-header .container-fluid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.layout-top-nav .logo .brand-image {
  height: 60px !important;
}

.navbar-blue {
  background-color: #4F8689;
  border-color: #4F8689;
}

.navbar-dark .navbar-nav .nav-link {
  color: #fff;
}

.header-buttons .nav-link {
  margin: 0 20px;
}

.message-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.text-red {
  color: #E31E24 !important;
}

.text-blue {
  color: #2C34A1 !important;
}

.header-top-links {
  display: flex;
  flex-wrap: wrap;
}

.header-top-links .nav-link {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  color: #484848;

}

.header-buttons .nav-link {
  height: auto;
}

.header-buttons .btn {
  font-size: 14px;
  font-weight: 400;
  background: #CD104D;
  border-radius: 25px;
  color: #fff;
}

.qr-btn {
  background: #FD841F !important;
}

.ecollect-btn {
  background: #2E26C6 !important;
}

.topup-btn {
  background: #EF6500 !important;
}

.fund-btn {
  background: #31C6D4 !important;
}

.header-link-col {
  /* margin:0 20px; */
}

.dbox-outer {
  cursor: pointer;
  border: 3px solid #E31E24;
  border-radius: 40px;
  position: relative;
  background: #FFFFFF;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 110px;
  margin-bottom: 15px;
}

.dbox-outer:after {
  content: " ";
  position: absolute;
  /* z-index: -1; */
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  border: 3px solid #2C34A1;
  border-radius: 37px;
}

/* .dbox-indent{
  border: 3px solid #2C34A1;
  border-radius: 40px;
  background: #FFFFFF;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 120px;
} */

.parentDisable {
  position: fixed;
  top: 0;
  left: 0;
  background: #060606;
  opacity: 0.8;
  z-index: 998;
  height: 100%;
  width: 100%;
}

.overlay-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  background: #666666;
  opacity: .8;
  z-index: 1000;
}

.dbox-text {
  font-weight: 700;
  font-size: 16px;
  color: #66686C;
}

.dbox-cols {
  display: flex;
  align-items: center;
}

.dbox-text2 {
  font-weight: 600;
  font-size: 16px;
  color: #625F60;
}


.page-title {
  font-weight: 600;
  font-size: 40px;
}

.card {
  box-shadow: none;
  border-radius: 6px;
}

.card-footer {
  background: none;
  padding-top: 0px;
}

.card-title {
  font-weight: 600;
  font-size: 24px;
  color: #2E26C6;
}

.kyc-submit-text {
  font-weight: 500;
  font-size: 18px;
  color: #2E26C6;
}

.form-control {
  background-color: #F5F5F5;
  border-color: #F5F5F5;
  font-size: 14px;
  /* height: calc(2.25rem + 4px); */
}

.login-box .form-control {
  background-color: #ffffff;
  border-color: #A8A8A8;

}

.login-box .form-control:focus {
  box-shadow: none;
  border-color: red;
}

.custom-file-label {
  background-color: #F5F5F5;
  border-color: #F5F5F5;
  font-size: 15px;
  padding-left: 75px;
  color: #A8A8A8;
}

.form-control {
  background-color: #fff;
  border-color: #A8A8A8;
}

.form-control.normal-light:focus {
  border-color: #4F8689;
}

.form-control.normal-dark {
  background-color: #fff;
  border-color: #484848;
}

.form-control.normal-dark:focus {
  border-color: #4F8689;
}

.custom-file-input:focus~.custom-file-label {
  border-color: #4F8689;
  box-shadow: none;
}

.custom-file-label::after {
  left: 0px;
  right: unset;
  background: #D9D9D9;
  border: 1px solid #484848;
  border-radius: 2px !important;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  height: auto;
  margin: 7px;
  padding: 2px 7px;
}

.uploadbtn .input-group-text {
  background-color: #F5F5F5;
  border-color: #F5F5F5;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-decoration-line: underline;
  color: #00AB41;

}

label:not(.form-check-label):not(.custom-file-label) {
  font-weight: 600;
  font-size: 14px;
  color: #000000;
}

.btn-cancel {
  text-decoration-line: underline;
  color: #E31E24;
}

.btn-shadow {
  box-shadow: inset 2px 4px 8px rgba(255, 255, 255, 0.3);
}

.btn-primary {
  background: #2E26C6;
}

.btn-primary:hover {
  border-color: #2E26C6;
  background: #2E26C6;
}

.btn-primary.focus,
.btn-primary:focus {
  background-color: #2E26C6;
  border-color: #2E26C6;
}

.btn-danger {
  background-color: #E31E24;
  border-color: #E31E24;
}

.btn-danger:hover,
.btn-danger.focus,
.btn-danger:focus {
  background-color: #E31E24;
  border-color: #E31E24;
}

.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active,
.show>.btn-danger.dropdown-toggle {
  background-color: #E31E24;
  border-color: #E31E24;
}

.btn-warning {
  color: #fff;
  background-color: #EF6500;
  border-color: #EF6500;
}

.btn-warning:hover,
.btn-warning.focus,
.btn-warning:focus {
  color: #fff;
  background-color: #EF6500;
  border-color: #EF6500;
}

.btn-warning:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active,
.show>.btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #EF6500;
  border-color: #EF6500;
}

.settings-text a {
  font-weight: 600;
  font-size: 16px;
  color: #000000;
}

.profile-info {
  background: #2E26C6 url(assets/bg-top.png) top right no-repeat !important;

}

.font-weight-semibold {
  font-weight: 600 !important;
}

.widget-user-2 .profile-info .widget-user-image>img {
  width: 80px;
  height: 80px;
}

.widget-user-2 .profile-info .widget-user-desc,
.widget-user-2 .profile-info .widget-user-username {
  margin-left: 95px;
}

.info-detail {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #484848;
}

.info-detail strong {
  font-weight: 600;
  color: #000000;
}

.info-detail li {
  padding: 9px 0;
}

.dashboard-header {
  border-bottom: 1px solid #000000;
  font-weight: 700;
  font-size: 24px;
  color: #2E26C6;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.dbox-icon {
  min-width: 80px;
}

.date-search {
  margin-bottom: 20px;
}

.form-control-border {
  background-color: #ffffff;
}

.form-control-border:focus {
  border-color: red;
}

.date-search .form-control-border {
  border-color: #000000;
}

.date-search .form-control-border:focus {
  border-color: red;
}

.date-search-label {
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  padding-left: 0.75rem;
}

.tabtext span {
  height: 40px;
  width: auto;
  display: block;
  text-align: center;
  margin-bottom: 5px;
}

.recharge-tabs .nav-tabs {
  border-color: rgba(0, 0, 0, 0.2);
  font-weight: 500;
  font-size: 12px;
  color: #000000;
}

.recharge-tabs .nav-tabs a {
  color: #000000;
}

.recharge-tabs .nav-tabs .nav-item.show .nav-link,
.recharge-tabs .nav-tabs .nav-link.active {
  color: #000000;
}

.recharge-tabs .nav-tabs .nav-link {
  margin-bottom: 0px;
  border: 0px;
  border-top: 3px solid #CBCBCB;
  border-radius: 0px;
  cursor: pointer;
}

.recharge-tabs .nav-tabs .nav-item.show .nav-link,
.recharge-tabs .nav-tabs .nav-link.active {
  border-color: transparent;
  background-color: #F2F2F2;
  border-top: 3px solid #2E26C6;
}

.recharge-tabs .nav-tabs .nav-link:focus,
.recharge-tabs .nav-tabs .nav-link:hover {
  border-color: transparent;
  border-top: 3px solid #2E26C6;
}

.inputgrouptext {
  position: absolute;
  right: 0px;
  top: 0px;
  /* z-index: 11; */
}

.inputgrouptext {

  font-weight: 400;
  color: #484848;
  padding: .375rem 1.75rem;
  margin: 1px;
}

.pr-110 {
  padding-right: 110px;
}

.account-detail-table .table td,
.account-detail-table .table th {
  padding: 5px 0;
}

.account-detail-table {
  color: #000000;
}

.account-detail-table td {
  color: #484848;
}

.history-table .table {
  border: 1px solid #dee2e6;
}

.history-table .table thead th {
  background-color: #dee2e6;
}

.rdt_TableCol {
  background-color: #D9D9D9;
  color: #131313;
}

.details {
  font-size: 16px;
}

.is-invalid .css-1s2u09g-control,
.is-invalid .css-1s2u09g-control:hover {
  border: 1px solid red;
}

/* border: 1px solid gray;
padding: 5px 4px;
border-radius: 3px;
margin: 0 3px; */
.icon-border {
  border: 1px solid gray;
  padding: 5px 4px;
  border-radius: 3px;
  margin: 0 3px;
  /* display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  -webkit-box-pack:center;
  -ms-flex-pack:center;
  justify-content:center;
  width:60px;
  height: 60px;
  border-radius: 50%;
  border: 2px solid red; */
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked {
  color: white !important;
}

/*****************************************
**************responsive *****************/

@media (min-width: 1200px) {
  .col-xl-3 {
    flex: 0 0 20%;
    max-width: 20%;
  }
}



@media (max-width: 992px) {
  .login-img {
    padding-right: 0px;
  }
}

@media (max-width: 768px) {

  .helplineno {
    display: none;
  }

  .helpline-text {
    display: none;
  }

  .login-outer {
    background: none !important;
  }

  .login-img {
    display: none;
  }

  .login-box {
    margin-left: auto;
    margin-right: auto;
  }

  .footer-social-media {
    padding: 10px 0;
  }

  .logo {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
  }

}

.numberCircle {
  border-radius: 50%;
  background: #fff;
  border: 2px solid red;
  color: red;
  text-align: center;
  font: 15px Arial, sans-serif;
  min-width: 20px;
  padding: 5px;
}

.export-button {
  width: 110px;
  margin-top: 21px;
}

.base {
  width: 200px !important;
}