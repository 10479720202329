.spinner {
    display: flex;
    justify-content: center;
    align-items: center;
}

.spinner img {
    animation: spin 1s linear infinite;
    /* Apply the spin animation */
}

/* @keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
} */