/*Project Name : RedBizAuthor Company : SpecThemesProject Date: 24 Feb, 2018Template Developer: vsafaryan50@gmail.com*/

/*==============================================TABLE OF CONTENT==============================================0. Imports1. Sections2. Headings3. Navbar4. Search5. Top Bar6. CountUps7. Helper Classesa) Text-contentb) Image-propertiesc) Buttonsd) Listse) Color Controlf) Video Boxg) Paginationh) Bordersi) Animationsf) Overlays8. Preloader9. Scroll to Top10. Number Boxes11. Service Boxes12. Clients13. Feature Boxes14. Progress Bars15. Price Tables16. Newsletter Form17. Footer18. Blog (Grid, Lists, Post)19. Testmonials20. Projects21. Partners (also Team)22.Feedback Form23. Team, Team Single24. Video Section25. Projects26. Project Single (Page)27. Accordions28. Service Single (Page)29. Callback30. Projects Masonry31. Under Construction32. Shop Single Slider33. Shop Single (Page)34. Shop Products35. 404 (Page)36. Contact (Page)37. Tabs38. Owl Carousel39. Pie Charts40. Chart JS==============================================[END] TABLE OF CONTENT==============================================*/

/*-----------------------------------------0. Imports-------------------------------------------*/

@import url("navigation.css");

/*-----------------------------------------1. Sections-------------------------------------------*/
.checkbox{
	width: 10%!important;
}
.contactbtn {
	margin-top: 4%;
  margin-left:40%;
  margin-bottom: 5%;
}

@media only screen and (max-width: 600px) {
.contactbtn{
    margin-left:0%;
  }
}


.labelbottom{
	margin-bottom: 10px!important;
}

label {
    color: #777!important;
    display: inline-block!important;
    margin-bottom:0px!important;
	font-size: 15px!important;
	font-weight:600!important;
}

.redfont{
 color:#de0000!important;
}
.bluefont{
	color:#0d04c0!important;
}
.readbtn{
	
    background: #0d04c0;
    color: #fff;
    font-size: 11px;
    font-weight: 500;
    border-radius: 50px;
    padding: 10px 15px 10px 15px;
    -webkit-transition: all .4s ease;
    -moz-transition: all .4s ease;
    transition: all .4s ease;

}

.red{
background-image: linear-gradient(to right, #dd0000 64% , #0d04be 40%)!important;

}
.bluebtn{
	background: #de0101!important;
}
.fontwhite{
	color:#fff!important;
	font-size: 20px!important;
}
.section-block {
	padding: 30px 0px 90px 0px;
	background-color: #fff;
}
.section-block-grey {
	padding: 30px 0px 90px 0px;
	background-color: #f9f9f9;
}
.section-block-dark {
	padding: 30px 0px 90px 0px;
	background-color: #222;
}
.section-block-parallax {
	position: relative;
	background-size: cover;
	background-attachment: fixed;
	background-position: 50% 50%;
	padding: 90px 0px 90px 0px;
}
.section-block-bg {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	padding: 90px 0px 90px 0px;
}
.section-block-transparent {
	padding: 30px 0px 90px 0px;
	background: transparent;
}
.background-top {
	background-position: center left;
}
.primary-bg {
	background: #e20b11 !important;
}
.no-repeat {
	background-repeat: no-repeat;
}
.section-clients {
	background: #fff;
	padding: 50px 0px;
}
.section-clients-grey {
	background: #f9f9f9;
	padding: 50px 0px;
}
.notice-section {
	background: #fff;
	padding: 60px 0px;
}
.notice-section-parallax {
	position: relative;
	background-attachment: fixed;
	padding: 60px 0px;
}
.notice-section-grey {
	background: #f7f7f7;
	padding: 60px 0px;
}
.notice-section-bg {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	padding: 60px 0px 60px 0px;
}
.notice-section h2,
.notice-section-grey h2,
.notice-section-bg h2 {
	position: relative;
	font-size: 42px;
	font-weight: 400;
	color: #111;
}
.notice-section h3,
.notice-section-grey h3,
.notice-section-bg h3 {
	position: relative;
	font-size: 35px;
	font-weight: 400;
	color: #111;
}
.notice-section h4,
.notice-section-grey h4,
.notice-section-bg h4 {
	position: relative;
	font-size: 30px;
	font-weight: 500;
	color: #111;
}
.notice-section h5,
.notice-section-grey h5,
.notice-section-bg h5 {
	position: relative;
	font-size: 25px;
	font-weight: 500;
	color: #111;
}
.notice-section h6,
.notice-section-grey h6,
.notice-section-bg h6 {
	position: relative;
	font-size: 20px;
	font-weight: 500;
	text-align: left;
	color: #111;
}
.dark-bg {
	background: #111;
}
.full-background {
	background-size: cover;
}
.notice-section-sm {
	padding: 10px 0px;
}
.notice-section-md {
	padding: 60px 0px;
}
.notice-section-lg {
	padding: 80px 0px;
}
.section-sm {
	padding: 30px 0px;
}
.section-md {
	padding: 40px 0px;
}
.section-lg {
	padding: 80px 0px;
}
.min-350 {
	min-height: 350px;
}
/*-----------------------------------------2. Headings-------------------------------------------*/

.section-heading {
	margin-bottom: 10px;
}
.section-heading h2 {
	position: relative;
	font-size: 42px;
	font-weight: 500;
	color: #111;
	line-height: 130%;
	margin-bottom: 10px;
}
.section-heading h3 {
	position: relative;
	font-size: 35px;
	font-weight: 600;
	color: #111;
	line-height: 130%;
	margin-bottom: 10px;
}
.section-heading h4 {
	position: relative;
	font-size: 30px;
	font-weight: 600;
	color: #111;
	line-height: 160%;
	margin-bottom: 10px;
}
.section-heading h5 {
	position: relative;
	font-size: 25px;
	font-weight: 600;
	color: #111;
	line-height: 160%;
	margin-bottom: 10px;
}
.section-heading h6 {
	position: relative;
	font-size: 20px;
	font-weight: 500;
	color: #111;
	line-height: 160%;
	margin-bottom: 10px;
}
.section-heading strong {
	font-weight: 600;
}
.section-heading-line {
	content: '';
	display: block;
	width: 100px;
	height: 3px;
	background: #e20b11;
	border-radius: 25%;
	margin: auto;
	margin-top: 15px;
	margin-bottom: 5px;
}
.section-heading-line-left {
	content: '';
	display: block;
	width: 100px;
	height: 3px;
	background: #e20b11;
	border-radius: 25%;
	margin-top: 15px;
	margin-bottom: 5px;
}
.section-heading p {
	font-size: 15px;
	font-weight: 400;
	color: #aaa;
	line-height: 160%;
	padding-top: 15px;
	margin-bottom: 0px;
}
.section-heading span {
	position: relative;
	font-size: 15px;
	font-weight: 500;
	color: #e20b11;
	line-height: 200%;
	margin-bottom: 10px;
}
.page-title-section {
	padding: 30px 0px 30px 0px;
	background-color:#ffffff;
	background-size: cover;
	background-position: center;
	text-align: center;
}
.white{
	color:#de0000!important;
}
.page-title-section h1 {
	font-size: 50px;
	font-weight: 600;
	letter-spacing: 1px;
	line-height: 1;
	color: #251dc3;
	text-align: center;
	margin-bottom: 10px;
}
.page-title-section ul li {
	display: inline-block;
}
.page-title-section ul li:after {
	content: ' \f105';
	color: #fff;
	font-family: 'Poppins';
	padding: 0px 10px;
}
.page-title-section ul li:last-child:after {
	content: none;
}
.page-title-section ul li a {
	color: #fff;
	font-size: 14px;
	font-weight: 500;
}
.page-title-section ul li a:hover {
	color: #111;
}
/*-----------------------------------------3. Navbar-------------------------------------------*/

.nav-menu > li:hover > a,
.nav-menu > li.active > a,
.nav-menu > li.focus > a {
	color: #090909;
}
.nav-menu > li:hover > a .submenu-indicator-chevron,
.nav-menu > .active > a .submenu-indicator-chevron,
.nav-menu > .focus > a .submenu-indicator-chevron {
	border-color: transparent #e20b11 #e20b11 transparent;
}
.nav-search-button:hover .nav-search-icon {
	color: #e20b11;
}
.nav-button {
	height: auto !important;
	margin: 18px 15px 0;
	padding: 8px 14px;
	display: inline-block;
	color: #fff;
	font-size: 14px;
	text-align: center;
	text-decoration: none;
	border-radius: 4px;
	background-color: #e20b11;
	-webkit-transition: opacity 0.3s;
	transition: opacity 0.3s;
}
.megamenu-list > li > a:hover {
	color: #e20b11;
}
@media (min-width: 991px) {
	#nav-transparent .nav-menu > li:hover > a,
	#nav-transparent .nav-menu > li.active > a,
	#nav-transparent .nav-menu > li.focus > a {
		color: #e20b11;
	}
	#nav-transparent .nav-menu > li:hover > a .submenu-indicator-chevron,
	#nav-transparent .nav-menu > .active > a .submenu-indicator-chevron,
	#nav-transparent .nav-menu > .focus > a .submenu-indicator-chevron {
		border-color: transparent #e20b11 #e20b11 transparent;
	}
	#nav-transparent .nav-dropdown > li:hover > a .submenu-indicator-chevron,
	#nav-transparent .nav-dropdown > .focus > a .submenu-indicator-chevron {
		border-color: transparent #e20b11 #e20b11 transparent;
	}
	#nav-transparent .nav-white-bg .nav-menu > li:hover > a {
		color: #0c0b0b;
	}
}
.nav-dropdown > li:hover > a,
.nav-dropdown > li.focus > a {
	color: #e20b11;
}
.nav-dropdown > li:hover > a .submenu-indicator-chevron,
.nav-dropdown > .focus > a .submenu-indicator-chevron {
	border-color: transparent #e20b11 #e20b11 transparent;
}
/*-----------------------------------------4. Search-------------------------------------------*/

#search_nav {
	display: inline-block;
	z-index: 9999;
}
.search-holder {
	color: #323232;
	margin-top: 18px;
	border-left: 1px solid #f7f7f7;
}
.search-button {
	padding-left: 12.5px;
	cursor: pointer;
}
@media (max-width: 991px) {
	.search-holder {
		border: none !important;
	}
	.search-button i {
		padding: 17px 0px;
		cursor: pointer;
	}
	.search-button i:after {
		font-family: 'Poppins';
		content: 'Search';
		font-size: 13px;
		text-transform: uppercase;
		font-weight: 700;
		display: inline-block;
		padding-left: 10px;
	}
	.search-overlay-content input {
		margin: 30px;
		max-width: 80%;
	}
	.search-overlay-content {
		position: relative;
		top: 15% !important;
		text-align: center;
	}
}
.search-overlay {
	height: 100%;
	width: 0;
	position: fixed;
	z-index: 1;
	top: 0;
	left: 0;
	background-color: rgb(0, 0, 0);
	background-color: rgba(0, 0, 0, 0.9);
	overflow-x: hidden;
	transition: 0.5s;
}
.search-overlay-content {
	position: relative;
	top: 35%;
	width: 100%;
	text-align: center;
	margin-top: 30px;
}
.search-overlay-content input {
	font-size: 35px;
	font-weight: 500;
	color: #fff;
	background: transparent;
	border: none;
	border-bottom: 2px solid #fff;
	opacity: 0.5;
	padding: 15px 15px;
}
.search-overlay a {
	padding: 8px;
	text-decoration: none;
	font-size: 36px;
	color: #818181;
	display: block;
	transition: 0.3s;
}
.search-overlay a:hover,
.search-overlay a:focus {
	color: #f1f1f1;
}
.search-overlay .closebtn {
	position: absolute;
	top: 20px;
	right: 30px;
	font-size: 60px;
}
@media screen and (max-height: 450px) {
	.search-overlay a {
		font-size: 20px
	}
	.search-overlay .closebtn {
		font-size: 40px;
		top: 15px;
		right: 35px;
	}
}
/*-----------------------------------------5. Top-Bar-------------------------------------------*/

@media (max-width: 991px) {
	#top-bar.hidden-sm-down {
		display: none !important;
	}
}
#top-bar {
	display: block;
	position: relative;
	z-index: 999;
	background: #2a2a2a;
	height: 45px;
}
.top-bar-welcome {
	display: inline-block;
}
.top-bar-welcome ul {
	padding-left: 0px;
	margin-bottom: 0px;
}
.top-bar-welcome li {
	font-size: 13px;
	font-weight: 500;
	color: #fff;
	list-style-type: none;
	margin: 0px;
	padding-top: 2px;
	margin-right: 5px;
}
.top-bar-info {
	display: inline-block;
}
.top-bar-info ul {
	padding-left: 0px;
	margin-bottom: 0px;
	padding-left: 10px;
	padding-right: 20px;
}
.top-bar-info li {
	font-size: 13px;
	font-weight: 500;
	color: #fff;
	list-style-type: none;
	display: inline-block;
	padding: 13.5px 5px 0px 5px;
	margin-bottom: 0px;
}
.top-bar-info li i {
	font-size: 13px;
	color: #e20b11;
	margin-right: 8px;
}
.social-icons {
	padding: 0px;
	float: right;
	margin: 0px;
}
.social-icons li {
	font-size: 14px;
	list-style-type: none;
	float: left;
	width: 35px;
	text-align: center;
	margin: 0px;
	padding: 13px 0px 13px 0px;
}
.social-icons li a {
	opacity: .7;
	color: #fff;
	-webkit-transition-duration: .3s;
	transition-duration: .3s;
}
.social-icons a:hover {
	opacity: 1;
}
/*-----------------------------------------6. CountUps-------------------------------------------*/

.counter-box {
	position: relative;
	text-align: center;
	margin: 15px 0px;
}
@media (max-width: 767px) {
	.counter-box {
		margin: 30px;
	}
}
.counter-box h4 {
	display: inline-block;
	font-size: 35px;
	font-weight: 600;
	color: #111;
}
.counter-box h4:after {
	content: '+';
}
.counter-box h3 {
	display: inline-block;
	font-size: 45px;
	font-weight: 600;
	color: #111;
}
.counter-box h3:after {
	content: '+';
}
.counter-box h2 {
	display: inline-block;
	font-size: 55px;
	font-weight: 600;
	color: #111;
}
.counter-box.disable-line h2 {
	margin-bottom: 0;
}
.counter-box h2:after {
	content: '+';
}
.counter-box p {
	font-size: 15px;
	font-weight: 500;
	color: #999;
	line-height: 1.7;
	margin-top: 10px;
}
.counter-box.disable-line p {
	font-size: 18px;
	margin-top: 0;
}
.counter-box p:before {
	content: '';
	display: block;
	background: #e20b11;
	height: 3px;
	width: 70px;
	margin: 10px auto;
}
.counter-box.disable-line p:before {
	content: none;
}
.counter-box.left-holder p:before {
	content: '';
	display: block;
	background: #e20b11;
	height: 3px;
	width: 70px;
	margin: 10px 0px;
}
/*-----------------------------------------7. Helper Classes-------------------------------------------*/

/*--------------------a) Text-content----------------------*/

.text-content p {
	font-size: 14px;
	font-weight: 400;
	color: #999;
	line-height: 190%;
	margin-bottom: 15px;
}
.text-content-big p {
	text-align:justify;
	font-size: 17px;
	font-weight: 400;
	color:#100f0f;
	line-height: 200%;
	margin-bottom: 15px;
}
.text-content-small p {
	font-size: 12px;
	font-weight: 400;
	color: #999;
	line-height: 200%;
	margin-bottom: 15px;
}
.text-uppercase {
	text-transform: uppercase;
}
.text-thin {
	font-weight: 400 !important;
}
.text-semi-bold {
	font-weight: 600 !important;
}
.text-bold {
	font-weight: 700 !important;
}
.text-bold p {
	font-weight: 700 !important;
}
/*--------------------b) Image-properties----------------------*/

.rounded-border {
	border-radius: 10px !important;
}
.semi-rounded {
	border-radius: 5px !important;
}
.no-rounded {
	border-radius: 0px !important;
}
.shadow-primary {
	-webkit-box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
	box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
}
.full-width {
	width: 100%;
}
.half-width {
	min-width: 50%;
}
.full-height {
	height: 100%;
}
/*--------------------c) Buttons----------------------*/

.primary-button {
	background: #e20b11;
	color: #fff;
	font-size: 11px;
	font-weight: 500;
	border-radius: 50px;
	padding: 10px 15px 10px 15px;
	-webkit-transition: all .4s ease;
	-moz-transition: all .4s ease;
	transition: all .4s ease;
}
.primary-button:hover {
	background: #222;
	color: #fff;
}
.primary-button-bordered {
	background: transparent;
	color: #e20b11;
	border: 2px solid #e20b11;
	font-size: 11px;
	font-weight: 500;
	border-radius: 50px;
	padding: 10px 15px 10px 15px;
	-webkit-transition: all .4s ease;
	-moz-transition: all .4s ease;
	transition: all .4s ease;
}
.primary-button-bordered.button-lg {
	display: inline-block;
	font-size: 17px;
	font-weight: 600;
	padding: 13px 38px 13px 38px !important;
}
.primary-button-bordered:hover {
	background: #e20b11;
	color: #fff;
}
.white-button {
	background: #fff;
	transition: background-position 0.5s;
	color: #111;
	border: 1px solid #ccc;
	font-size: 11px;
	font-weight: 500;
	border-radius: 50px;
	padding: 10px 15px 10px 15px;
	-webkit-transition: all .4s ease;
	-moz-transition: all .4s ease;
	transition: all .4s ease;
}
.white-button:hover {
	color: #111;
	transform: translateY(-.4rem);
	box-shadow: 0 12px 36px rgba(0, 0, 0, 0.1);
}
.dark-button {
	background: #111;
	color: #fff;
	font-size: 11px;
	font-weight: 500;
	border-radius: 50px;
	padding: 10px 15px 10px 15px;
	-webkit-transition-duration: .3s;
	transition-duration: .3s;
}
.dark-button:hover {
	color: #fff;
	background: #e20b11;
}
.bordered-button {
	background: transparent;
	border: 2px solid #e20b11;
	color: #fff;
	font-size: 12px;
	font-weight: 600;
	border-radius: 50px;
	padding: 10px 15px 10px 15px;
	-webkit-transition: all .4s ease;
	-moz-transition: all .4s ease;
	transition: all .4s ease;
}
.bordered-button:hover {
	border: 2px solid #e20b11;
	background: #e20b11;
	color: #fff;
}
.slider-btn {
	font-family: 'Poppins';
	transition: 0.3s !important;
}
.button-tag {
	display: inline-block;
	font-size: 12px;
	font-weight: 500;
	margin: 5px 0px 0px 0px;
	padding: 8px 15px 8px 15px !important;
}
.button-xs {
	display: inline-block;
	font-size: 10px;
	font-weight: 500;
	padding: 8px 17px 8px 17px;
}
.button-sm {
	display: inline-block;
	font-weight: 500;
	font-size: 12px;
	padding: 11px 22px 11px 22px;
}
.button-md {
	display: inline-block;
	font-size: 14px;
	font-weight: 500;
	padding: 13px 25px 13px 25px;
}
.button-lg {
	display: inline-block;
	font-size: 17px;
	font-weight: 600;
	padding: 14px 38px 14px 38px !important;
}
.pricing-box-1-button {
	position: relative;
	margin-top: 25px;
}
.pricing-box-1-button a {
	background: transparent;
	font-weight: 500;
	color: #e20b11;
	display: inline-block;
	border: 2px solid #e20b11;
	border-radius: 5px;
	padding: 8px 22px;
}
.pricing-box-1-button a:hover {
	background: #e20b11;
	color: #fff;
}
.menu-tag {
	display: inline-block;
	background: #e20b11;
	color: #fff;
	font-size: 10px;
	font-weight: 500;
	border-radius: 5px;
	padding: 3px 5px;
	margin-left: 5px;
}
/*--------------------d) Lists----------------------*/

.primary-list {
	padding-left: 0px;
}
.primary-list li {
	list-style-type: none;
	color: #222;
	font-size: 13px;
	font-weight: 500;
	margin-top: 15px;
}
.primary-list li i {
	color: #e20b11;
	padding-right: 10px;
}
.dark-list {
	padding-left: 0px;
}
.dark-list li {
	list-style-type: none;
	color: #222;
	font-size: 14px;
	font-weight: 500;
	margin-top: 15px;
}
.dark-list li i {
	color: #222;
	padding-right: 10px;
}
.white-list {
	padding-left: 0px;
}
.white-list li {
	list-style-type: none;
	color: #fff;
	font-size: 14px;
	font-weight: 500;
	margin-top: 15px;
}
.white-list li i {
	color: #e20b11;
	padding-right: 15px;
}
/*--------------------e) Color Control----------------------*/

.primary-color,
.primary-color h1,
.primary-color h2,
.primary-color h3,
.primary-color h4,
.primary-color h5,
.primary-color h6,
.primary-color p,
.primary-color i,
.primary-color span {
	color: #e20b11 !important;
}
.dark-color h1,
.dark-color h2,
.dark-color h3,
.dark-color h4,
.dark-color h5,
.dark-color h6,
.dark-color p,
.dark-color i,
.dark-color span {
	color: #111 !important;
}
.white-color,
.white-color h1,
.white-color h2,
.white-color h3,
.white-color h4,
.white-color h5,
.white-color h6,
.white-color p,
.white-color i,
.white-color span {
	color: #fff !important;
}
/*--------------------f) Video Box----------------------*/

.video-video-box {
	position: relative;
	display: block;
	width: 100%;
	height: auto;
}
.video-video-box img {
	width: 100%;
}
.video-video-box-overlay {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	height: 100%;
	width: 100%;
	opacity: 1;
	transition: .5s ease;
}
.video-video-box-button {
	position: absolute;
	overflow: hidden;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
}
.video-video-box-button button {
	color: #fff;
	font-size: 25px;
	background: rgba(0, 0, 0, .8);
	width: 100px;
	height: 100px;
	border-radius: 50%;
	padding-top: 5px;
	padding-left: 12px;
	-webkit-transition-duration: .3s;
	transition-duration: .3s;
}
.video-video-box-button button:hover {
	color: #111;
	background: #fff;
}
@media (max-width: 991px) {
	.video-video-box-button button {
		color: #222;
		font-size: 15px;
		background: rgba(255, 255, 255, .7);
		width: 60px;
		height: 60px;
		border-radius: 50%;
		padding-top: 1px;
		padding-left: 9px;
		-webkit-transition-duration: .3s;
		transition-duration: .3s;
	}
}
/*--------------------g) Pagination----------------------*/

.paginator {
	display: inline-block;
	border-radius: 10px;
	font-size: 15px;
	font-weight: 600;
	color: #2d2d2d;
	background: #f1f1f1;
	padding: 10px 15px 10px 15px;
	margin: 3px;
}
.paginator:hover {
	color: #fff;
	background: #bda87f;
}
.paginator:focus {
	color: #fff;
	background: #bda87f;
}
.active-page {
	color: #fff;
	background: #bda87f;
}
/*--------------------h) Borders----------------------*/

.border-top {
	border-top: 1px solid #ececec !important;
}
.border-bottom {
	border-bottom: 1px solid #ececec;
}
.border-left {
	border-left: 1px solid #ececec;
}
.border-right {
	border-right: 1px solid #ececec;
}
.border-full {
	border: 1px solid #ececec;
}
/*--------------------i) Animations----------------------*/

@-webkit-keyframes pulse {
	25% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
	75% {
		-webkit-transform: scale(.85);
		transform: scale(.85);
	}
}
@keyframes pulse {
	25% {
		-webkit-transform: scale(1);
		-ms-transform: scale(1);
		transform: scale(1);
	}
	75% {
		-webkit-transform: scale(.85);
		-ms-transform: scale(.85);
		transform: scale(.85);
	}
}
/*--------------------f) Overlays----------------------*/

.black-overlay-5:before {
	background: rgba(0, 0, 0, 0.05);
	content: "";
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 0;
}
.black-overlay-10:before {
	background: rgba(0, 0, 0, 0.1);
	content: "";
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 0;
}
.black-overlay-20:before {
	background: rgba(0, 0, 0, 0.2);
	content: "";
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 0;
}
.black-overlay-30:before {
	background: rgba(0, 0, 0, 0.3);
	content: "";
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 0;
}
.black-overlay-40:before {
	background: rgba(0, 0, 0, 0.4);
	content: "";
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 0;
}
.black-overlay-50:before {
	background: rgba(0, 0, 0, 0.5);
	content: "";
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 0;
}
.black-overlay-60:before {
	background: rgba(0, 0, 0, 0.6);
	content: "";
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 0;
}
.black-overlay-70:before {
	background: rgba(0, 0, 0, 0.7);
	content: "";
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 0;
}
.black-overlay-80:before {
	background: rgba(0, 0, 0, 0.8);
	content: "";
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 0;
}
.black-overlay-90:before {
	background: rgba(0, 0, 0, 0.9);
	content: "";
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 0;
}
.black-overlay-100:before {
	background: rgba(0, 0, 0, 1);
	content: "";
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 0;
}
/*-----------------------------------------8. Preloader-------------------------------------------*/

#preloader {
	position: fixed;
	left: 0;
	top: 0;
	z-index: 99999;
	width: 100%;
	height: 100%;
	overflow: visible;
	background: #fff;
	display: table;
	text-align: center;
}
.loader {
	display: table-cell;
	vertical-align: middle;
	height: 100%;
	width: 100%;
	position: relative;
	width: 200px;
	height: 200px;
}
.loader-icon {
	width: 80px;
	height: 80px;
	border: 2px solid #e20b11;
	border-right-color: #eee;
	border-radius: 50%;
	position: relative;
	animation: loader-rotate 1s linear infinite;
	margin: 0 auto;
}
@keyframes loader-rotate {
	0% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(360deg);
	}
}
/*-----------------------------------------9. Scroll To Top Button-------------------------------------------*/

.scroll-to-top {
	font-size: 20px;
	padding: 6px;
	text-align: center;
	color: #fff;
	text-decoration: none;
	position: fixed;
	bottom: 80px;
	right: 10px;
	display: none;
	border-radius: 50%;
	background: #333;
	width: 40px;
	height: 40px;
	z-index: 9999;
	outline: none;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
}
.scroll-to-top i {
	color: #fff;
}
.scroll-to-top:hover {
	color: #111;
	background: #fff;
}
.scroll-to-top:hover i {
	color: #111;
}
.scroll-to-top:visited {
	color: #111;
	text-decoration: none;
}
/*-----------------------------------------10. Number Boxes-------------------------------------------*/

.number-box {
	text-align: center;
	padding: 20px;
	margin: 15px 0px;
	background: #fff;
	border-radius: 3px;
}
.number-box h3 {
	color: #111;
	line-height: 1;
	font-size: 60px;
	font-weight: 200;
}
.number-box-line {
	content: '';
	border-bottom: 1px dashed #dedede;
	width: 100%;
	margin: 20px auto;
}
.number-box-line:before {
	content: '';
	display: block;
	margin: auto;
	margin-bottom: -7.5px;
	height: 15px;
	width: 15px;
	border: 2px solid #e20b11;
	background: #fff;
	border-radius: 50%;
}
.number-box h4 {
	color: #111;
	font-size: 20px;
	font-weight: 400;
}
.number-box p {
	color: #999;
	font-size: 14px;
	font-weight: 400;
	margin-top: 15px;
	line-height: 1.5;
}
.plane-box {
	margin: 0 auto;
	text-align: center;
}
.plane-box-item {
	position: relative;
	display: inline-block;
	width: 33.33%;
	margin-right: -3px;
	padding-right: 70px;
	padding-left: 70px;
}
.plane-box-item:after {
	position: absolute;
	top: 14px;
	left: 50%;
	display: block;
	width: 100%;
	height: 1px;
	content: '';
	background-color: #e20b11;
	opacity: 0.2;
}
.plane-box-item:last-child:after {
	content: none;
}
.plane-box-text {
	position: relative;
	display: inline-block;
}
.plane-box-text h3 {
	color: #ccc;
	font-size: 50px;
	font: italic 60px Lora;
	margin: 15px 0px;
	-webkit-transition-duration: .5s;
	transition-duration: .5s;
}
.plane-box-item:hover .plane-box-text h3 {
	color: #222;
}
.plane-box-text h4 {
	color: #222;
	font-size: 20px;
	font-weight: 500;
	margin: 15px 0px;
}
.plane-box-text p {
	color: #999;
	font-size: 15px;
	font-weight: 400;
	line-height: 1.6;
}
.plane-box-decor {
	position: relative;
	z-index: 1;
	display: inline-block;
	width: 29px;
	height: 29px;
}
.plane-box-decor:after {
	background-color: #e20b11;
	display: block;
	width: 1px;
	height: 15px;
	margin: 19px auto 0;
	content: '';
}
.plane-box-decor:before {
	background-color: #e20b11;
	position: absolute;
	top: 10px;
	left: 10px;
	display: block;
	width: 9px;
	height: 9px;
	content: '';
	border-radius: 50%;
	box-shadow: 0 0 0 5px rgba(207, 81, 90, 0.2);
}
@media (max-width: 991px) {
	.plane-box-item {
		padding-right: 40px;
		padding-left: 40px;
	}
}
@media (max-width: 768px) {
	.plane-box-item {
		padding-right: 20px;
		padding-left: 20px;
	}
}
@media (max-width: 425px) {
	.plane-box-item {
		display: block;
		width: 100%;
		padding-bottom: 25px;
		margin: 15px 0px;
	}
	.plane-box-item:after {
		display: none;
	}
}
@media (min-width: 1440px) {
	.plane-box-decor {
		display: table;
	}
	.plane-box-decor {
		margin: 0 auto !important;
	}
}
/*-----------------------------------------11. Service Boxes-------------------------------------------*/

/*--------------Styles 1----------------*/

.owl-carousel .service-box {
	margin: 15px 15px 25px 15px;
}
.service-box {
	background: #fff;
	overflow: hidden;
	border: 1px solid #eee;
	border-radius: 5px;
	-webkit-box-shadow: 0px 10px 20px 0px rgba(50, 50, 50, 0.12);
	-moz-box-shadow: 0px 10px 20px 0px rgba(50, 50, 50, 0.12);
	box-shadow: 0px 10px 20px 0px rgba(50, 50, 50, 0.12);
	margin: 15px 0px;
	-webkit-transition-duration: .3s;
	transition-duration: .3s;
}
.service-inner-box {
	padding: 0px 20px 20px 20px;
}
.service-icon-box {
	width: 25%;
	float: left;
	padding-top: 5px;
}
.service-content-box {
	width: 75%;
	float: left;
}
.service-box img {
	width: 100%;
	margin-bottom: 25px;
}
.service-box h3 {
	color: #111;
	font-size: 18px;
	font-weight: 500;
}
.service-box p {
	color: #999;
	font-size: 15px;
	font-weight: 400;
	margin-top: 10px;
}
.service-box i {
	color: #e20b11;
	font-size: 53px;
	-webkit-transition-duration: .3s;
	transition-duration: .3s;
}
@media (max-width: 991px) {
	.service-box i {
		font-size: 33px;
	}
}
@media (max-width: 767px) {
	.service-box i {
		font-size: 45px;
	}
}
.service-box:hover i {
	color: #111;
}
/*--------------Styles 2----------------*/

.service-block {
	position: relative;
	margin-top: 30px;
	margin-bottom: 10px;
	-webkit-transition-duration: .3s;
	transition-duration: .3s;
}
.service-block img {
	width: 100%;
	border-radius: 5px;
	margin-bottom: 10px;
}
.service-block-number {
	width: 15%;
	float: left;
}
.service-block-title {
	width: 75%;
	float: left;
}
.service-block-number h5 {
	color: #e20b11;
	font-size: 35px;
	font-weight: 500;
}
.service-block-title h4 {
	color: #e20b11;
	font-size: 17px;
	font-weight: 500;
	padding-top: 12.5px;
	padding-left: 10px;
}
.service-block-title h4 a:hover {
	color: #e20b11;
}
.service-block p {
	color: #999;
	font-size: 15px;
	font-weight: 400;
}
/*--------------Styles 3----------------*/

.service-simple {
	position: relative;
	background: #fff;
	border: 1px solid #ececec;
	border-radius: 5px;
	overflow: hidden;
	margin: 15px 0px;
	-webkit-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16);
	-moz-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16);
	box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16);
}
.service-simple img {
	width: 100%;
}
.service-simple-inner {
	position: relative;
	padding: 25px 25px 20px 25px;
}
.service-simple h4 {
	font-size: 20px;
	color: #111;
	font-weight: 500;
	margin-bottom: 10px;
}
.service-simple h4:before {
	content: '●';
	color: #e20b11;
	margin-right: 10px;
}
.service-simple p {
	font-size: 15px;
	color: #999;
	line-height: 1.7;
	margin-bottom: 0px;
}
.service-simple-button a {
	display: inline-block;
	background: #111;
	color: #fff;
	border-radius: 5px;
	text-align: center;
	font-size: 14px;
	font-weight: 500;
	width: 100%;
	margin: 15px 0px 2px 0px;
	padding: 15px 20px 15px 20px;
	-webkit-transition: all .4s ease;
	-moz-transition: all .4s ease;
	transition: all .4s ease;
}
.service-simple-button a:hover {
	background: #e20b11;
}
/*--------------Styles 4----------------*/

.service-box-car-2 {
	display: block;
	background: #fff;
	text-align: center;
	box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.07);
	border-radius: 5px;
	padding: 30px 25px;
	margin: 15px;
	margin-bottom: 30px;
}
.service-box-car-2 i {
	display: block;
	color: #e20b11;
	font-size: 55px;
	margin-bottom: 5px;
}
.service-box-car-2 h4 {
	color: #111;
	font-size: 18px;
	margin: 18px 0px;
}
.service-box-car-2 p {
	font-size: 15px;
	color: #999;
	line-height: 1.7;
	margin-bottom: 0px;
}
/*--------------Styles 5----------------*/

.services-box-3 {
	display: block;
	background: #fff;
	overflow: hidden;
	box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.12);
	border-radius: 5px;
	margin: 15px 0px;
}
.services-box-3 img {
	width: 100%;
}
.services-box-3-header {
	padding: 20px;
}
.services-box-3-header h4 {
	font-size: 19px;
}
.services-box-3 span {
	display: inline-block;
	background: #e20b11;
	color: #fff;
	font-weight: 500;
	font-size: 13px;
	border-radius: 5px;
	padding: 2px 5px;
	margin-bottom: 10px;
}
/*--------------Styles 6----------------*/

.serv-section-2 {
	position: relative;
	border: 1px solid #eee;
	background: #fff;
	box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16);
	border-radius: 5px;
	overflow: hidden;
	padding: 30px;
}
.serv-section-2:before {
	position: absolute;
	top: 0;
	right: 0px;
	z-index: 0;
	content: " ";
	width: 120px;
	height: 120px;
	background: #f5f5f5;
	border-bottom-left-radius: 136px;
	transition: all 0.4s ease-in-out;
	-webkit-transition: all 0.4s ease-in-out;
}
.serv-section-2:hover:before {
	background: #e20b11;
}
.serv-section-2-icon {
	position: absolute;
	top: 18px;
	right: 22px;
	max-width: 100px;
	z-index: 1;
	text-align: center
}
.serv-section-2-icon i {
	color: #e20b11;
	font-size: 48px;
	line-height: 65px;
	transition: all 0.4s ease-in-out;
	-webkit-transition: all 0.4s ease-in-out;
}
.serv-section-2:hover .serv-section-2-icon i {
	color: #fff;
}
.serv-section-top-text {
	width: 180px;
	height: auto;
}
.serv-section-desc {
	position: relative;
}
.serv-section-2 h4 {
	color: #333;
	font-size: 20px;
	font-weight: 500;
	line-height: 1.5;
}
.serv-section-2 h5 {
	color: #333;
	font-size: 17px;
	font-weight: 400;
	line-height: 1;
	margin-top: 5px;
}
.serv-section-2 p {
	margin-top: 25px;
	padding-right: 50px;
}
.serv-section-2-act:before {
	background: #e20b11;
}
.serv-section-2-act:hover:before {
	background: #f5f5f5;
}
.serv-section-2-icon-act i {
	color: #fff;
}
.serv-section-2-act:hover .serv-section-2-icon-act i {
	color: #e20b11 !important;
}
@media (max-width: 991px) {
	.serv-section-2 {
		margin-top: 20px;
	}
	.serv-section-2:before {
		margin-top: 0px;
	}
	.serv-section-2-icon i {
		position: absolute;
		right: -3px;
		top: 0px;
	}
}
@media (max-width: 425px) {
	.serv-section-2 {
		padding: 30px 10px;
	}
}
/*-----------------------------------------12. Clients-------------------------------------------*/

.clients img {
	opacity: .5;
	-webkit-transition-duration: .3s;
	transition-duration: .3s;
}
.clients img:hover {
	opacity: 1;
}
/*-----------------------------------------13. Feature Boxes-------------------------------------------*/

/*--------------Styles 1----------------*/

.simple-feature-box {
	background: #fff;
	border-radius: 5px;
	padding: 15px;
	margin: 15px 0px;
	-webkit-transition-duration: .3s;
	transition-duration: .3s;
}
.simple-feature-box:hover {
	-webkit-box-shadow: 0px 0px 19px 0px rgba(50, 50, 50, 0.26);
	-moz-box-shadow: 0px 0px 19px 0px rgba(50, 50, 50, 0.26);
	box-shadow: 0px 0px 19px 0px rgba(50, 50, 50, 0.26);
}
.simple-feature-box-icon {
	text-align: center;
	margin: auto;
}
.simple-feature-box-icon i {
	font-size: 18px;
	width: 32px;
	height: 32px;
	color: #fff;
	background: #e20b11;
	border-radius: 50%;
	padding: 6.5px;
	padding-left: 9px;
}
.simple-feature-box-content h4 {
	font-size: 18px;
	color: #111;
	font-weight: 500;
	margin-bottom: 10px;
}
@media (max-width: 767px) {
	.simple-feature-box-content {
		padding-left: 25px;
	}
}
/*--------------Styles 2----------------*/

.feature-block {
	position: relative;
	text-align: center;
	background: #fff;
	padding: 15px;
	margin: 15px 0px;
}
.feature-block-icon i {
	color: #e20b11;
	font-size: 55px;
	-webkit-transition-duration: .3s;
	transition-duration: .3s;
}
.feature-block h4 {
	color: #111;
	font-size: 18px;
	font-weight: 500;
	margin: 20px 0px 10px 0px;
}
.feature-block p {
	font-size: 15px;
	color: #999;
	line-height: 1.7;
	margin-bottom: 0px;
}
.feature-block:hover .feature-block-icon {
	-webkit-animation-name: pulse;
	animation-name: pulse;
	-webkit-animation-duration: .7s;
	animation-duration: .7s;
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
}
/*--------------Styles 3----------------*/

.feature-box {
	position: relative;
	background: #fff;
	padding: 20px;
	border-radius: 5px;
	text-align: center;
	margin: 20px 0px 20px 0px;
	-webkit-box-shadow: 2px 2px 20px 0px rgba(50, 50, 50, 0.21);
	-moz-box-shadow: 2px 2px 20px 0px rgba(50, 50, 50, 0.21);
	box-shadow: 2px 2px 20px 0px rgba(50, 50, 50, 0.21);
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
}
.feature-box:hover {
	-webkit-box-shadow: 4px 4px 22px 0px rgba(50, 50, 50, 0.29);
	-moz-box-shadow: 4px 4px 22px 0px rgba(50, 50, 50, 0.29);
	box-shadow: 4px 4px 22px 0px rgba(50, 50, 50, 0.29);
}
.feature-box i {
	color: #e20b11;
	font-size: 60px;
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
}
.feature-box:hover i {
	color: #111;
}
.feature-box h4 {
	color: #111;
	font-size: 18px;
	font-weight: 500;
	line-height: 170%;
	margin: 15px 0px;
}
.feature-box p {
	color: #999;
	font-size: 14px;
	font-weight: 500;
	line-height: 170%;
}
.feature-box-3 {
	display: block;
	text-align: center;
	background: #fff;
	border-radius: 4px;
	margin: 15px 0;
	-webkit-transition: all .3s;
	-o-transition: all .3s;
	transition: all .3s;
}
.feature-box-3 i {
	display: flex;
	align-items: center;
	justify-content: center;
	color: #e20b11;
	font-size: 55px;
	background-image: linear-gradient(to bottom, rgba(210, 30, 43, .1), transparent);
	width: 125px;
	height: 125px;
	border-radius: 50%;
	margin: 0 auto 15px auto;
}
.feature-box-3 h4 {
	font-size: 20px;
	font-weight: 500;
	margin-bottom: 15px;
}
.feature-box-3 p {
	font-size: 16px;
}
/*--------------Styles 4----------------*/

.feature-flex {
	position: relative;
	margin: 30px 0px;
	-webkit-transition-duration: .3s;
	transition-duration: .3s;
}
.feature-flex-icon {
	float: left;
	width: 25%;
	margin-top: 20px;
	-webkit-transition-duration: .3s;
	transition-duration: .3s;
}
.feature-flex-icon i {
	color: #fff;
	border-radius: 50%;
	background-image: linear-gradient(120deg, rgba(210, 30, 43, .6) 0%, #e20b11 100%);
	padding: 21.5px 22px;
	font-size: 40px;
	-webkit-transition-duration: .3s;
	transition-duration: .3s;
}
@media (max-width: 991px) {
	.feature-flex-icon {
		text-align: center;
		margin: auto;
	}
	.feature-flex-icon i {
		color: #fff;
		border-radius: 50%;
		background-image: linear-gradient(120deg, rgba(210, 30, 43, .6) 0%, #e20b11 100%);
		padding: 17px;
		font-size: 32px;
	}
}
@media (max-width: 767px) {
	.feature-flex-icon {
		text-align: center;
		margin: auto;
	}
	.feature-flex-icon i {
		color: #fff;
		border-radius: 50%;
		background-image: linear-gradient(120deg, rgba(210, 30, 43, .6) 0%, #e20b11 100%);
		padding: 16px;
		font-size: 33px;
	}
}
.feature-flex-content {
	float: left;
	width: 75%;
	padding-left: 10px;
}
.feature-flex-content h4 {
	color: #111;
	font-size: 21px;
	font-weight: 400;
}
.feature-flex-content h4 a {
	color: #111;
	font-size: 21px;
	font-weight: 400;
}
.feature-flex-content p {
	color: #999;
	font-size: 15px;
	font-weight: 400;
	margin-top: 10px;
}
.feature-flex:hover .feature-flex-icon {
	margin-top: 10px;
}
/*--------------Styles 5----------------*/

.feature-flex-square {
	position: relative;
	border-radius: 5px;
	margin: 30px 0px;
	-webkit-transition-duration: .3s;
	transition-duration: .3s;
}
.feature-flex-square-icon {
	float: left;
	width: 25%;
	margin-top: 5px;
	-webkit-transition-duration: .3s;
	transition-duration: .3s;
}
.feature-flex-square-icon i {
	color: #e20b11;
	border-radius: 10px;
	padding: 10px;
	font-size: 55px;
	-webkit-transition-duration: .3s;
	transition-duration: .3s;
}
@media (max-width: 991px) {
	.feature-flex-square-icon {
		text-align: center;
		margin: 23px auto;
	}
	.feature-flex-square-icon i {
		padding: 14px;
		font-size: 30px;
	}
}
.feature-flex-square-content {
	float: left;
	width: 75%;
	padding-left: 10px;
}
.feature-flex-square-content h4 {
	color: #111;
	font-size: 19px;
	font-weight: 500;
}
.feature-flex-square-content h4 a {
	color: #111;
	font-size: 19px;
	font-weight: 500;
}
.feature-flex-square-content p {
	color: #999;
	font-size: 15px;
	font-weight: 400;
	margin-top: 10px;
	margin-bottom: 15px;
}
.feature-flex-square-content-button {
	display: inline-block;
	color: #111;
	font-size: 14px;
	font-weight: 500;
}
.feature-flex-square-content-button:after {
	content: '\f0da';
	font-size: 14px;
	font-family: 'Poppins';
	color: #111;
	margin-left: 7px;
	-webkit-transition-duration: .3s;
	transition-duration: .3s;
}
.feature-flex-square-content-button:hover {
	color: #e20b11;
}
.feature-flex-square-content-button:hover:after {
	color: #e20b11;
}
/*--------------Styles 6----------------*/

.feature-box-long {
	position: relative;
	text-align: center;
	border: 1px solid #ececec;
	border-radius: 5px;
	background: #fff;
	-webkit-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16);
	-moz-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16);
	box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16);
	margin: 15px 0px;
	padding: 30px 40px 35px 40px;
	-webkit-transition-duration: .3s;
	transition-duration: .3s;
}
.feature-box-long:hover {
	-webkit-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.29);
	-moz-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.29);
	box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.29);
}
.feature-box-long h3 {
	font-size: 19px;
	font-weight: 500;
	color: #111;
	margin: 25px 0px 15px 0px;
}
.feature-box-long i {
	color: #e20b11;
	font-size: 65px;
	-webkit-transition-duration: .3s;
	transition-duration: .3s;
}
.feature-box-long p {
	font-size: 15px;
	font-weight: 400;
	color: #aaa;
}
/*--------------Styles 7----------------*/

.feature-box-2 {
	position: relative;
	background: #fff;
	border-radius: 5px;
	margin: 20px 0px 20px 0px;
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
}
@media (max-width: 767px) {
	.feature-box-2 {
		text-align: center;
		padding: 0px 30px;
	}
}
.feature-box-2 i {
	color: #e20b11;
	font-size: 50px;
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
}
.feature-box-2 h4 {
	color: #111;
	font-size: 18px;
	font-weight: 500;
	line-height: 170%;
	margin: 15px 0px 5px 0px;
}
.feature-box-2 p {
	color: #999;
	font-size: 13px;
	font-weight: 400;
	line-height: 1.8;
}
/*--------------Styles 8----------------*/

.feature-box-4 {
	position: relative;
	background: #fff;
	display: flex;
	border-top: 3px solid #e20b11;
	overflow: hidden;
	box-shadow: 0 10px 20px 0 rgba(0, 0, 0, .1);
	padding: 30px;
	margin: 15px 0;
}
.feature-box-4-icon {
	flex: 0 0 80px;
}
.feature-box-4-icon i {
	color: #e20b11;
	font-size: 50px;
}
.feature-box-4 h4 {
	font-size: 19px;
	color: #222;
	font-weight: 500;
	margin-bottom: 10px;
}
.feature-box-4 h4,
.feature-box-4 p,
.feature-box-4 .feature-box-4-link {
	position: relative;
	z-index: 99;
}
.feature-box-4-link {
	color: #222;
	font-size: 15px;
	font-weight: 500;
	display: inline-block;
	margin-top: 10px;
}
.feature-box-4-link i {
	font-size: 11px;
	margin-left: 3px;
}
.feature-box-4-link:hover {
	color: #e20b11;
}
.feature-box-4-icon-bg {
	position: absolute;
	color: #eee;
	font-size: 125px;
	bottom: -30px;
	right: -20px;
	z-index: 9;
}
/*--------------Styles 9----------------*/

.features-slider {
	position: relative;
	z-index: 999;
}
@media (min-width: 991px) {
	.features-slider {
		margin-top: -299px;
	}
	.rev-slider-long {
		height: 870px !important;
	}
	.rev-slider-long .forcefullwidth_wrapper_tp_banner,
	.rev-slider-long .rev_slider {
		height: 870px !important;
	}
}
.feature-box-5 {
	width: 100%;
	background: #101010;
	color: #fff;
	border-top: 2px solid #e20b11;
	padding: 30px 30px 60px 30px;
	-webkit-transition-duration: 0.5s;
	transition-duration: 0.5s;
}
@media (max-width: 991px) {
	.feature-box-5 {
		margin: 15px 0;
	}
}
.feature-box-5.emphasised {
	background: #e20b11;
	color: #fff;
	box-shadow: 0px 10px 50px 1px rgba(0, 0, 0, 0.3);
}
.feature-box-5.emphasised i {
	color: #fff;
}
.feature-box-5 i {
	color: #e20b11;
	font-size: 60px;
	-webkit-transition-duration: 0.5s;
	transition-duration: 0.5s;
}
.feature-box-5 h4 {
	color: #fff;
	font-size: 23px;
	margin: 30px 0 10px 0;
}
.feature-box-5 p {
	color: #fff;
	font-size: 15px;
}
.feature-box-5:hover {
	background: #e20b11;
	color: #fff;
	box-shadow: 0px 10px 50px 1px rgba(0, 0, 0, 0.3);
}
.feature-box-5:hover i {
	color: #fff;
}
/*--------------Styles 10----------------*/

.feature-box-6 {
	position: relative;
}
.feature-box-6-text {
	text-align: left;
	margin-left: 130px;
	padding: 15px 15px 15px 100px;
}
.feature-box-6-text h6 {
	color: #fff;
	font-size: 14px;
	font-weight: 500;
	line-height: 1.4;
	letter-spacing: 1px;
}
.feature-box-6-text h4 {
	color: #fff;
	font-size: 40px;
	font-weight: 600;
	letter-spacing: 1px;
	line-height: 1.2;
	padding: 15px 0px;
}
.feature-box-6-text h4 span {
	color: #e20b11;
	font-size: 40px;
	font-weight: 600;
	letter-spacing: 1px;
}
.feature-box-6-text p {
	color: #999;
	font-size: 14px;
	font-weight: 400;
	padding-top: 10px;
}
.feature-counter-box {
	position: relative;
	text-align: left;
}
.feature-counter-box h4 {
	display: inline-block;
	color: #111;
	font-size: 50px;
	font-weight: 600;
	padding: 0px;
}
.feature-counter-box p {
	font-size: 14px;
	font-weight: 400;
	color: #999;
	line-height: 1.7;
	padding: 0px;
}
.feature-box-6-icon-text {
	background-color: rgba(0, 0, 0, 0.6);
	position: relative;
	padding: 30px;
	margin: 1px;
}
.feature-box-6-icon-text i {
	color: #e20b11;
	font-size: 50px;
}
.feature-box-6-icon-text h4 {
	color: #fff;
	font-size: 22px;
	font-weight: 500;
	padding: 15px 0px;
}
.feature-box-6-icon-text p {
	color: #fff;
	font-size: 15px;
	font-weight: 400;
}
@media (max-width: 1024px) {
	.feature-box-6-text {
		text-align: left;
		margin-left: 70px;
		padding: 15px 15px 15px 70px;
	}
	.feature-counter-box h4 {
		font-size: 35px;
	}
	.feature-box-6-icon {
		margin-right: 30px;
	}
	.feature-box-6-icon-text {
		padding: 20px 15px;
	}
	.feature-box-6-icon-text h4 {
		font-size: 18px;
	}
}
@media (max-width: 768px) {
	.feature-box-6-icon {
		margin-right: 0px;
	}
	.feature-box-6-icon-text {
		padding: 20px 20px;
	}
	.feature-box-6-icon-text h4 {
		font-size: 18px;
	}
	.feature-box-6-text {
		text-align: left;
		margin-left: 30px;
		padding: 15px 15px 15px 0px;
	}
}
/*-----------------------------------------14. Progress Bars-------------------------------------------*/

.progress-text {
	font-size: 13px;
	font-weight: 500;
	color: #333;
	margin-bottom: 5px;
	margin-top: 20px;
}
.custom-progress {
	height: 5px;
	border-radius: 50px;
	box-shadow: none;
}
.custom-bar {
	height: 100%;
	background-color: #e20b11;
	box-shadow: none;
}
.custom-bar-dark {
	height: 100%;
	background-color: #111;
	box-shadow: none;
}
.progress-medium {
	height: 8px;
	border-radius: 50px;
	box-shadow: none;
}
.progress-bold {
	height: 12px;
	border-radius: 50px;
	box-shadow: none;
}
/*-----------------------------------------15. Price Tables-------------------------------------------*/

/*--------------Styles 1----------------*/

.price-table {
	position: relative;
	border-radius: 10px;
	overflow: hidden;
	background: #fff;
	margin: 15px 0px;
	-webkit-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16);
	-moz-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16);
	box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16);
}
.price-table-header {
	background: #111;
	text-align: center;
	padding: 30px;
	padding-top: 0px;
}
.price-table-recommended {
	position: relative;
	z-index: 9999;
	transform: scale(1.09);
}
@media (max-width: 767px) {
	.price-table-recommended {
		position: relative;
		z-index: 9999;
		transform: scale(1);
	}
}
.price-table-header h5 {
	position: relative;
	color: #111;
	font-weight: 500;
	display: inline-block;
	margin-right: -7px;
	text-align: center;
	font-size: 17px;
	background: #fff;
	-moz-border-radius-bottomright: 15px;
	-webkit-border-bottom-right-radius: 15px;
	border-bottom-right-radius: 15px;
	-moz-border-radius-bottomleft: 15px;
	-webkit-border-bottom-left-radius: 15px;
	border-bottom-left-radius: 15px;
	padding: 5px 15px;
}
.price-table-header h4 {
	color: #fff;
	font-weight: 300;
	font-size: 60px;
	margin: 15px 0px 10px 0px;
}
.price-table-header h4 sup {
	font-size: 35px;
	margin: 0px 5px;
}
.price-table-header span {
	color: #fff;
	font-weight: 400;
	font-size: 16px;
}
.price-table-content {
	padding: 20px;
}
.price-table-content ul {
	padding-left: 0px;
	padding-bottom: 0px;
}
.price-table-content ul li {
	display: block;
	list-style-type: none;
	color: #111;
	font-size: 14px;
	font-weight: 500;
	padding: 15px;
}
.price-table-content ul li:before {
	content: '\f00c';
	font-family: 'Poppins';
	display: inline-block;
	color: #e20b11;
	margin-right: 12px;
}
.price-table-content ul li:nth-child(2) {
	background: #eee;
}
.price-table-content a {
	display: inline-block;
	color: #fff;
	font-size: 14px;
	font-weight: 500;
	background: #111;
	border-radius: 10px;
	padding: 15px 15px;
	width: 100%;
	margin-top: 15px;
}
.price-table-content a:hover {
	background: #e20b11;
}
/*--------------Styles 2----------------*/

.pricing-list {
	text-align: center;
	border-radius: 10px;
	background: #fff;
	border: 2px solid #ececec;
	padding: 30px 40px;
	margin: 20px 0px;
	-webkit-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16);
	-moz-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16);
	box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16);
	-webkit-transition-duration: 0.5s;
	transition-duration: 0.5s;
}
.pricing-list h4 {
	color: #111;
	font-size: 25px;
	font-weight: 500;
	letter-spacing: 2px;
	margin-bottom: 20px;
}
.pricing-list h2 {
	display: inline-block;
	color: #111;
	font-size: 65px;
	font-weight: 400;
	letter-spacing: 2px;
}
.pricing-list h2 sup {
	color: #111;
	font-size: 30px;
	font-weight: 500;
}
.pricing-list span {
	display: inline-block;
	color: #e20b11;
	font-weight: 500;
}
.pricing-list ul {
	margin-top: 20px;
}
.pricing-list ul li {
	color: #666;
	font-size: 15px;
	font-weight: 400;
	padding: 7px;
}
.pricing-list ul li:after {
	content: '';
	display: block;
	width: 240px;
	max-width: 100%;
	height: 1px;
	background: #ececec;
	margin: 0 auto;
	margin-top: 10px;
}
.pricing-list-button {
	background: #e20b11;
	margin: 20px 15px 0px 15px;
	border: 2px solid #e20b11;
	border-radius: 5px;
	-webkit-transition-duration: .3s;
	transition-duration: .3s;
}
.pricing-list-button:hover {
	background: #fff;
}
.pricing-list-button a {
	display: block;
	color: #fff;
	font-size: 16px;
	font-weight: 500;
	padding: 8px;
	-webkit-transition-duration: .3s;
	transition-duration: .3s;
}
.pricing-list-button:hover a {
	color: #e20b11;
}
.pricing-list strong {
	position: absolute;
	color: #fff;
	font-size: 16px;
	font-weight: 500;
	background: #e20b11;
	width: 65px;
	height: 65px;
	padding: 6px;
	padding-top: 20px;
	border-radius: 50%;
	top: 0;
	right: 0;
}
/*--------------Styles 3----------------*/

.pricing-list-2 {
	background: #fff;
	padding: 45px 30px;
	border-radius: 10px;
	border: 2px solid #e8e8e8;
	text-align: center;
	box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.1);
	z-index: 1;
	margin: 15px 0px;
	-webkit-transition-duration: .3s;
	transition-duration: .3s;
}
.pricing-list-2-emphasise {
	position: relative;
	z-index: 2;
	transform: scale(1.1);
	margin: 15px 0px;
}
@media (max-width: 767px) {
	.pricing-list-2-emphasise {
		transform: scale(1);
	}
}
.pricing-list-2-top {
	width: 80px;
	height: 80px;
	background: #fff;
	border: 2px solid #e20b11;
	line-height: 83px;
	display: inline-block;
	border-radius: 50px;
	color: #e20b11;
	font-size: 35px;
	-webkit-transition-duration: .3s;
	transition-duration: .3s;
}
.pricing-list-2:hover .pricing-list-2-top {
	background: #fff;
	color: #e20b11;
}
.pricing-list-2 h4 {
	color: #333;
	font-size: 22px;
	font-weight: 500;
	letter-spacing: 1px;
	margin-top: 20px;
}
.pricing-list-2-price {
	margin-top: 20px;
}
.pricing-list-2-price h3 sup {
	color: #333;
	font-size: 25px;
	font-weight: 400;
}
.pricing-list-2-price h3 {
	color: #333;
	font-size: 50px;
	font-weight: 400;
	display: inline-block;
}
.pricing-list-2-price span {
	color: #777;
	font-size: 15px;
	font-weight: 500;
}
.pricing-list-2-prod {
	margin-top: 20px;
}
.pricing-list-2-prod li {
	color: #999;
	font-size: 13px;
	font-weight: 500;
	line-height: 1.5;
	padding: 5px 0px;
}
.pricing-list-2-prod li:after {
	content: '';
	display: block;
	width: 180px;
	max-width: 100%;
	height: 1px;
	background: #eee;
	margin: 10px auto 0;
}
.pricing-list-2-button {
	background: #fff;
	border: 2px solid #e20b11;
	display: inline-block;
	border-radius: 5px;
	margin-top: 20px;
	-webkit-transition-duration: .3s;
	transition-duration: .3s;
}
.pricing-list-2-button a {
	display: inline-block;
	color: #e20b11;
	font-weight: 500;
	padding: 7px 50px;
	-webkit-transition-duration: .3s;
	transition-duration: .3s;
}
.pricing-list-2-button:hover {
	background: #e20b11;
	border: 2px solid #e20b11;
}
.pricing-list-2-button:hover a {
	color: #fff;
}
/* active version */

.pricing-list-2-button-act {
	background: #e20b11;
	border: 2px solid #e20b11;
	display: inline-block;
	border-radius: 5px;
	margin-top: 20px;
	-webkit-transition-duration: .3s;
	transition-duration: .3s;
}
.pricing-list-2-button-act a {
	display: inline-block;
	color: #fff;
	font-weight: 500;
	padding: 7px 50px;
	-webkit-transition-duration: .3s;
	transition-duration: .3s;
}
.pricing-list-2-button-act:hover {
	background: #fff;
	border: 2px solid #e20b11;
}
.pricing-list-2-button-act:hover a {
	color: #e20b11;
}
.pricing-list-2-top-act {
	background: #e20b11;
	color: #fff;
}
.pricing-box-1 {
	position: relative;
	border: 1px solid #eee;
	overflow: hidden;
	border-radius: 5px;
	-webkit-box-shadow: 1px 3px 10px 1px rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 1px 3px 10px 1px rgba(0, 0, 0, 0.1);
	box-shadow: 1px 3px 10px 1px rgba(0, 0, 0, 0.1);
	margin-top: 20px;
}
.pricing-box-back {
	position: relative;
	background-repeat: no-repeat;
	background-position: right center;
	padding: 40px;
	z-index: 99;
}
.pricing-box-emphasis:after {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	display: block;
	height: 100%;
	width: 100%;
	background: #e20b11;
	opacity: 0.7;
	z-index: -1;
}
.pricing-box-back h6 {
	color: #fff;
	font-weight: 500;
	font-size: 15px;
}
.pricing-box-back h6 span {
	float: right;
	border: 1px solid #ccc;
	border-radius: 30px;
	padding: 2px 10px;
	color: #fff;
	font-weight: 500;
	letter-spacing: 0.5px;
	font-size: 15px;
}
.pricing-box-back h3 {
	color: #fff;
	font-weight: 800;
	font-size: 55px;
	margin-top: 20px;
	line-height: 1.5;
}
.pricing-box-back h3 sup {
	color: #fff;
	font-weight: 800;
	font-size: 36px;
	margin-right: 5px;
}
.pricing-box-back h5 {
	color: #fff;
	font-weight: 500;
	font-size: 15px;
	letter-spacing: 0.5px;
}
/*-- pricing-box-back-2 --*/

.pricing-box-back-2 h6,
.pricing-box-back-2 h6 span {
	color: #000;
}
.pricing-box-back-2 h3,
.pricing-box-back-2 h3 sup,
.pricing-box-back-2 h5 {
	color: #333;
}
.pricing-box-1-text {
	padding: 30px 30px;
	border-top: 1px solid #eee;
}
.pricing-box-1-text ul li {
	color: #555;
	font-size: 15px;
	font-weight: 500;
	padding: 5px 0px;
}
.pricing-box-1-text ul li i {
	color: #e20b11;
	font-size: 15px;
	margin-right: 5px;
}
.pricing-box-1-button {
	position: relative;
	margin-top: 25px;
}
.pricing-box-1-button a {
	background: transparent;
	color: #e20b11;
	display: inline-block;
	border: 2px solid #e20b11;
	border-radius: 5px;
	padding: 8px 20px;
}
.pricing-box-1-button a:hover {
	background: #e20b11;
	color: #fff;
}
@media (max-width: 991px) {
	.pricing-box-1-text {
		padding: 30px 10px;
		border-top: 1px solid #eee;
	}
	.pricing-box-1-text ul li {
		font-size: 14px;
	}
	.pricing-box-back h6 span {
		display: none;
	}
}
.pricing-box-3 {
	position: relative;
	text-align: center;
	background: #fff;
}
@media (min-width: 767px) {
	.pricing-box-3-premium {
		transform: scale(1.1);
		z-index: 999;
		-webkit-box-shadow: 0px 0px 38px -12px rgba(122, 122, 122, 0.79);
		-moz-box-shadow: 0px 0px 38px -12px rgba(122, 122, 122, 0.79);
		box-shadow: 0px 0px 38px -12px rgba(122, 122, 122, 0.79);
	}
}
.pricing-box-3-premium .pricing-box-3-top {
	background: #000;
}
.pricing-box-3-top {
	background: #000;
	padding: 12px 0px;
}
.pricing-box-3-top h4 {
	color: #fff;
	font-size: 14px;
	font-weight: 500;
	text-transform: uppercase;
}
.pricing-box-3-price {
	background: #f9f9f9;
	padding: 30px 0px;
}
.pricing-box-3-price h3 {
	color: #333;
	font-size: 50px;
	font-weight: 500;
}
.pricing-box-3-price h3 sup {
	color: #333;
	font-size: 25px;
	font-weight: 500;
	letter-spacing: 3px;
}
.pricing-box-3-price h5 {
	color: #999;
	font-size: 14px;
	font-weight: 500;
	letter-spacing: 1px;
	margin-top: -5px;
}
.pricing-box-3-info {
	border: 1px solid #f5f5f5;
}
.pricing-box-3-prod {
	padding: 30px 10px;
}
.pricing-box-3-prod ul li {
	color: #999;
	font-size: 14px;
	font-weight: 500;
	line-height: 1.5;
	border-bottom: 1px solid #eee;
	padding: 10px 0;
}
.pricing-box-3-prod ul li:last-child {
	border-bottom: none;
}
.pricing-box-3-button {
	position: relative;
	margin-top: 25px;
}
.pricing-box-3-button a {
	background: #fff;
	color: #000;
	font-size: 13px;
	display: inline-block;
	border: 2px solid #000;
	font-weight: 500;
	border-radius: 5px;
	padding: 6px 30px;
}
.pricing-box-3-button a:hover {
	background: #000;
	color: #fff;
}
.pricing-box-3-premium .pricing-box-3-button a {
	background: #000;
	color: #fff;
	font-size: 13px;
	font-weight: 500;
	display: inline-block;
	border: 2px solid #000;
	border-radius: 5px;
	padding: 6px 30px;
}
.pricing-box-3-premium .pricing-box-3-button a:hover {
	background: #fff;
	color: #000;
}
/*-----------------------------------------16. Newsletter Form-------------------------------------------*/

/*--------------Styles 1----------------*/

.newsletter-form input {
	display: inline-block;
	background: #fff;
	color: #111;
	font-size: 14px;
	font-weight: 500;
	outline: 0;
	border: 2px solid #eee;
	min-width: 270px;
	max-width: 100%;
	-moz-border-radius-topleft: 30px;
	-webkit-border-top-left-radius: 30px;
	border-top-left-radius: 30px;
	-moz-border-radius-bottomleft: 30px;
	-webkit-border-bottom-left-radius: 30px;
	border-bottom-left-radius: 30px;
	padding: 12px 20px;
}
.newsletter-form button {
	display: inline-block;
	background: #e20b11;
	color: #fff;
	font-size: 14px;
	font-weight: 500;
	-moz-border-radius-topright: 30px;
	-webkit-border-top-right-radius: 30px;
	border-top-right-radius: 30px;
	-moz-border-radius-bottomright: 30px;
	-webkit-border-bottom-right-radius: 30px;
	border-bottom-right-radius: 30px;
	padding: 14px 20px;
}
.newsletter-form button:hover {
	background: #111;
}
@media (max-width: 767px) {
	.newsletter-form input {
		border-radius: 30px;
		width: 100%;
		display: block;
	}
	.newsletter-form button {
		border-radius: 30px;
		width: 100%;
		display: block;
		margin-top: 15px;
	}
}
/*-----------------------------------------17. Footer-------------------------------------------*/

footer {
	margin-top: 3%;
	background: #111;
	padding: 60px 0px 20px 0px;
}
footer p {
	font-size: 14px;
	color: #999;
	line-height: 1.7;
}
footer h3 {
	font-size: 24px;
	font-weight: 400;
	color: #fff;
	line-height: 1;
}
footer h3:after {
	content: '';
	display: block;
	/* width: 70px; */
	height: 2px;
	background: #e20b11;
	margin-top: 12px;
}
@media (max-width: 991px) {
	footer h3 {
		margin-top: 30px;
	}
}
.footer-social-icons ul li {
	display: inline-block;
	background: #333;
	border-radius: 50%;
	color: #fff;
	margin-right: 10px;
	margin-bottom: 5px;
	-webkit-transition-duration: .3s;
	transition-duration: .3s;
}
.footer-social-icons ul li a {
	display: block;
	font-size: 15px;
	color: #fff;
	width: 35px;
	height: 35px;
	padding: 11px;
	padding-top: 8px;
}
.footer-social-icons ul li:hover {
	background: #e20b11;
}
.footer-list {
	margin-top: 20px;
}
.footer-list li {
	list-style-type: none;
	color: #aaa;
	padding: 6px 0px;
	text-align: left;
}
.footer-list li a:before {
	content: '\f105';
	font-family: 'Poppins';
	color: #aaa;
	padding-right: 8px;
	-webkit-transition-duration: .3s;
	transition-duration: .3s;
}
.footer-list li a {
	font-size: 15px;
	font-weight: 400;
	color: #aaa;
}
.footer-list li a:hover {
	color: #fff;
}
.footer-list li a:hover:before {
	color: #fff;
}
.footer-recent-post {
	margin: 15px 0px;
}
.footer-recent-post-thumb {
	float: left;
	width: 25%;
}
.footer-recent-post-content {
	float: left;
	width: 75%;
	padding-left: 10px;
}
.footer-recent-post-content a {
	display: block;
	color: #fff;
	font-size: 15px;
	font-weight: 400;
	margin-top: 5px;
}
.footer-recent-post-content span {
	color: #ccc;
	font-size: 13px;
	font-weight: 400;
}
.footer-tags a {
	display: inline-block;
	font-size: 11px;
	padding: 10px 15px;
	color: #fff;
	font-weight: 400;
	background: #222;
	margin-top: 4px;
	border-radius: 5px;
}
.footer-tags a:hover {
	background: #e20b11;
}
.footer-bar {
	border-top: 1px solid rgba(255, 255, 255, .1);
	padding-top: 20px;
	margin-top: 30px;
}
.footer-bar span {
	color: #e20b11;
	font-size: 15px;
	font-weight: 400;
}
.footer-bar p {
	color: #fff;
	font-size: 15px;
	font-weight: 400;
}
/*-----------------------------------------18. Blog-------------------------------------------*/

/*-----------------Blog Grid (Style 1)-------------------*/

.blog-grid {
	position: relative;
	background: #fff;
	border: 1px solid #eee;
	border-radius: 5px;
	overflow: hidden;
	-webkit-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16);
	-moz-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16);
	box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16);
	margin: 25px 0px 25px 0px;
}
.blog-grid img {
	width: 100%;
}
.data-box-grid {
	display: block;
	float: right;
	position: absolute;
	text-align: center;
	background: #e20b11;
	left: 15px;
	top: 15px;
	min-width: 60px;
	padding: 5px;
}
.data-box-grid h4 {
	color: #fff;
	font-size: 30px;
	font-weight: 400;
	letter-spacing: 2px;
	border-bottom: 1px solid rgba(255, 255, 255, .3);
	margin-bottom: 5px;
}
.data-box-grid p {
	color: #fff;
	font-size: 14px;
	font-weight: 400;
}
.blog-grid-text {
	padding: 25px;
}
.blog-grid-text span {
	color: #e20b11;
	font-size: 13px;
	font-weight: 500;
	letter-spacing: 4px;
	text-transform: uppercase;
}
.blog-grid-text h4 {
	color: #111;
	font-size: 20px;
	font-weight: 500;
	margin: 5px 0px 5px 0px
}
.blog-grid-text ul li {
	display: inline-block;
	color: #999;
	font-size: 14px;
	font-weight: 500;
	margin: 10px 25px 10px 0px;
}
.blog-grid-text ul li i {
	color: #e20b11;
	font-size: 14px;
	font-weight: 500;
	margin-right: 10px;
}
.blog-grid-text p {
	color: #999;
	font-size: 15px;
	font-weight: 400;
	line-height: 170%;
	border-bottom: 1px solid #ececec;
	padding-bottom: 15px;
}
/*------------------Blog Grid (Style 2)--------------------*/

.owl-carousel .blog-grid-simple {
	margin: 10px 15px 25px 15px;
}
.blog-grid-simple {
	position: relative;
	background: #fff;
	border-radius: 5px;
	border: 1px solid #ececec;
	-webkit-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16);
	-moz-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16);
	box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16);
	padding: 30px;
	margin: 15px 0px;
}
.blog-grid-simple h4 {
	color: #111;
	font-size: 21px;
	font-weight: 500;
	margin-bottom: 15px;
}
.blog-grid-simple h4 a {
	color: #111;
	font-size: 20px;
	font-weight: 500;
	margin-bottom: 15px;
}
.blog-grid-simple h4 a:before {
	content: '\f0c1';
	font-family: 'Poppins';
	color: #e20b11;
	margin-right: 10px;
	-webkit-transition-duration: .3s;
	transition-duration: .3s;
}
.blog-grid-simple h4 a:hover {
	color: #e20b11;
}
.blog-grid-simple p {
	color: #aaa;
	font-size: 15px;
	font-weight: 400;
}
.blog-grid-simple-date i {
	color: #888;
	font-size: 20px;
}
.blog-grid-simple-date h5 {
	color: #888;
	font-size: 13px;
	margin-top: 3px;
}
.blog-grid-simple-content {
	border-top: 1px solid #ececec;
	padding-top: 20px;
	margin-top: 15px;
}
.blog-grid-simple-content a {
	display: inline-block;
	color: #e20b11;
	font-weight: 600;
	font-size: 14px;
	margin-top: 2px;
}
.blog-grid-simple-content a:after {
	content: '\f105';
	font-size: 14px;
	font-family: 'Poppins';
	color: #e20b11;
	margin-left: 10px;
	-webkit-transition-duration: .3s;
	transition-duration: .3s;
}
@media (max-width: 767px) {
	.blog-grid-simple-content a:after {
		display: none;
	}
}
.blog-grid-simple-content a:hover {
	color: #111;
}
.blog-grid-simple-content a:hover:after {
	color: #111;
}
/*------------------Blog Grid (Style 3)--------------------*/

.blog-box {
	position: relative;
	margin: 20px 0px;
}
.blog-box-img {
	margin-bottom: 50px;
}
.blog-box-img img {
	width: 100%;
	border-radius: 5px;
}
.blog-box-text {
	padding: 0px 20px;
}
.blog-box-text .blog-box-date {
	display: inline-block;
	background: #e20b11;
	border-radius: 3px;
	padding: 10px 25px;
	position: absolute;
	bottom: 230px;
}
.blog-box-text .blog-box-date h4 {
	color: #fff;
	font-size: 30px;
	font-weight: 500;
	padding: 0px;
}
.blog-box-text .blog-box-date h5 {
	color: #fff;
	font-size: 18px;
	font-weight: 500;
	padding: 0px;
}
.blog-box-text h6 {
	color: #999;
	font-size: 13px;
	font-weight: 500;
	letter-spacing: 0.5px;
}
.blog-box-text a h4 {
	color: #333;
	font-size: 23px;
	font-weight: 500;
	margin: 12px 0px;
	-webkit-transition-duration: .3s;
	transition-duration: .3s;
}
.blog-box-text a h4:hover {
	color: #e20b11;
}
.blog-box-text p {
	color: #777;
	font-size: 15px;
	font-weight: 400;
	line-height: 1.6;
}
@media (max-width: 992px) {
	.blog-box-text .blog-box-date {
		position: absolute;
		bottom: 300px;
	}
}
@media (max-width: 767px) {
	.blog-box-text .blog-box-date {
		position: absolute;
		bottom: 250px;
	}
}
@media (max-width: 436px) {
	.blog-box-text .blog-box-date {
		position: absolute;
		bottom: 320px;
	}
}
/*------------------Blog List--------------------*/

.blog-list-simple {
	margin: 20px 0px 30px 0px;
}
.data-box-simple {
	display: block;
	float: right;
	position: absolute;
	text-align: center;
	background: #e20b11;
	left: 20px;
	top: 5px;
	min-width: 60px;
	padding: 5px;
}
.data-box-simple h4 {
	color: #fff;
	font-size: 30px;
	font-weight: 400;
	letter-spacing: 2px;
	border-bottom: 1px solid #fff;
	margin-bottom: 5px;
}
.data-box-simple p {
	color: #fff;
	font-size: 14px;
	font-weight: 400;
}
.blog-list-simple-text {
	border-bottom: 1px solid #e8e8e8;
	padding-bottom: 25px;
}
.blog-list-simple-text p {
	font-size: 14px;
	color: #aaa;
	line-height: 1.9;
}
.blog-list-simple-text span {
	color: #e20b11;
	font-size: 20px;
	font-weight: 500;
	letter-spacing:1px;
	text-transform: uppercase;
}
.blog-list-simple-text h4 {
	color: #111;
	font-size: 20px;
	font-weight: 500;
	margin: 5px 0px 10px 0px;
}
.blog-list-simple-text ul {
	margin: 10px 0px;
}
.blog-list-simple-text ul li {
	color: #999;
	font-size: 14px;
	font-weight: 500;
	padding: 3px 0px;
}
.blog-list-simple-text ul li i {
	color: #e20b11;
	margin-right: 10px;
	font-size: 14px;
}
/*------------------Blog Post--------------------*/

.blog-list-left {
	margin: 30px 0px 20px 0px;
}
.blog-title-box h2 {
	color: #111;
	font-size: 27px;
	font-weight: 500;
	margin: 20px 0px;
}
.blog-title-box:after {
	content: '';
	display: block;
	width: 300px;
	max-width: 100%;
	height: 2px;
	background: #ececec;
	margin-top: 10px;
	margin: 15px 0px;
}
.blog-title-box span {
	color: #999;
	font-weight: 400;
	margin-right: 40px;
}
.blog-title-box span i {
	color: #e20b11;
	font-size: 16px;
	margin-right: 8px;
}
.blog-post-content p {
	color: #999;
	font-size: 15px;
	font-weight: 400;
	line-height: 180%;
	margin: 15px 0px 15px 0px;
}
.blockquote {
	background: #f1f1f1;
	border-left: 4px solid #e20b11;
	font-size: 16px;
	font-weight: 500;
	margin: 25px 0px 25px 0px;
	padding: 20px 30px 30px 30px;
}
.blockquote p {
	color: #111;
	font-size: 15px;
	font-weight: 500;
	line-height: 190%;
	letter-spacing: 0.5px;
}
.blockquote h4 {
	color: #111;
	font-size: 20px;
	font-weight: 400;
	letter-spacing: 0.5px;
}
.data-box {
	display: block;
	float: right;
	position: relative;
	text-align: center;
	background: #e20b11;
	right: 20px;
	top: -95px;
	min-width: 60px;
	padding: 5px;
}
.data-box h4 {
	color: #fff;
	font-size: 30px;
	font-weight: 400;
	letter-spacing: 2px;
	border-bottom: 1px solid #fff;
	margin-bottom: 5px;
}
.data-box strong {
	color: #fff;
	font-size: 14px;
	font-weight: 400;
}
/*search START*/

#search-input {
	padding: 0px;
	border: none;
	border: solid 1px #e8e8e8;
	border-radius: 5px;
	background: #fff;
	padding: 5px;
	margin-top: 30px;
}
#search-input input {
	font-weight: 400;
	border: 0;
	border-radius: 10px;
	box-shadow: none;
}
#search-input button {
	margin: 2px 0 0 0;
	background: none;
	box-shadow: none;
	border: 0;
	outline: none !important;
	padding: 0 11px 0 13px;
	border-left: solid 1px #e8e8e8;
}
#search-input .glyphicon-search {
	font-size: 13px;
	color: #111;
}
.blog-list-left-heading {
	margin: 30px 0px;
	padding-top: 15px;
}
.blog-list-left-heading:after {
	content: '';
	display: block;
	width: 80px;
	height: 2px;
	background: #e20b11;
	margin-top: 10px;
	margin: 10px 0px;
}
.blog-list-right h4 {
	color: #111;
	font-size: 20px;
	font-weight: 400;
	margin: 0px 0px 10px 0px;
}
/*Category*/

.blog-categories ul {
	margin-top: 20px;
	padding: 0px;
}
.blog-categories ul li {
	display: block;
	position: relative;
	list-style-type: none;
	border-bottom: 1px solid #ececec;
}
.blog-categories ul li a {
	display: block;
	font-size: 15px;
	font-weight: 500;
	padding: 18px 0px 18px 0px;
	color: #333;
}
.blog-categories ul li a:before {
	content: "\f00c";
	font-family: 'Poppins';
	color: #333;
	font-size: 14px;
	margin-right: 10px;
	-webkit-transition-duration: .3s;
	transition-duration: .3s;
}
.blog-categories ul li a:hover {
	color: #e20b11;
}
.blog-categories ul li a:hover:before {
	margin-right: 15px;
	color: #e20b11;
}
/* Latest News*/

.latest-posts-img img {
	border-radius: 50px;
	padding: 2px;
	border: 2px solid #ececec;
}
.latest-posts {
	margin: 5px 0px;
	padding: 15px 0px;
	border-bottom: 1px solid #ececec;
}
@media (max-width: 767px) {
	.latest-posts-text {
		margin-top: 10px;
	}
	.blog-post-left {
		margin-top: 20px;
	}
}
.latest-posts-text a {
	display: block;
	font-size: 14px;
	font-weight: 500;
	color: #111;
	margin-bottom: 10px;
	margin-top: 10px;
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
}
.latest-posts-text a:hover {
	color: #e20b11;
}
.latest-posts-text span {
	font-size: 14px;
	font-weight: 500;
	color: #e20b11;
}
/*Archives*/

.archives ul {
	margin-top: 20px;
	padding: 0px;
}
.archives ul li {
	list-style-type: none;
	border-bottom: 1px solid #ececec;
	padding: 13px 0px 13px 0px;
}
.archives ul li a {
	color: #444;
	font-size: 14px;
	font-weight: 500;
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
}
.archives ul li a:hover {
	color: #e20b11;
	font-size: 14px;
}
.archives ul li span {
	float: right;
	color: #e20b11;
	font-size: 13px;
	font-weight: 500;
}
/*Share Links*/

.blog-share {
	list-style-type: none;
	margin-top: 20px;
}
.blog-share ul {
	background: #f1f1f1;
	padding: 15px;
	border-radius: 5px;
}
.blog-share ul li {
	display: inline-block;
	padding-right: 20px;
}
.blog-share ul li a {
	color: #6b6b6b;
	font-size: 15px;
}
.blog-share ul li a:hover {
	color: #e20b11;
}
/*comment form*/

.comment-block {
	background: #fff;
	border-radius: 5px;
	border: 1px solid #eee;
	padding: 20px;
}
.blog-comments {
	background: #f7f7f7;
	border-radius: 5px;
	padding: 30px;
}
.blog-comments h3 {
	font-size: 24px;
	font-weight: 500;
	color: #111;
	margin-top: 30px;
}
.blog-comment-user {
	border-bottom: 1px solid #e8e8e8;
	padding-top: 25px;
	padding-bottom: 25px;
}
.blog-comment-user img {
	border-radius: 5px;
}
.blog-comment-user h6 {
	display: inline-block;
	color: #111;
	font-size: 16px;
	margin: 0px;
	margin-right: 15px;
}
.blog-comment-user strong {
	color: #e20b11;
	font-size: 12px;
	font-weight: 500;
}
.blog-comment-user p {
	color: #777;
	font-size: 13px;
	font-weight: 500;
	line-height: 170%;
	margin-top: 10px;
}
/*form*/

.comment-form input,
.comment-form textarea {
	font-weight: 500 !important;
	border-radius: 5px;
	font-size: 13px;
	border: 1px solid #ececec;
	background: #fff;
	color: #111 !important;
	outline: none;
	width: 100%;
	padding: 12px;
	margin-top: 30px;
	transition: .3s;
}
.comment-form input:focus {
	border-color: #cecece;
}
.comment-form textarea {
	overflow: hidden;
	color: #999;
	font-size: 13px;
	font-weight: 500;
	border: none;
	border: 1px solid #ececec;
	border-radius: 5px;
	width: 100%;
	margin-top: 30px;
	padding: 13px 15px 13px 15px;
	-webkit-transition-duration: .3s;
	transition-duration: .3s;
	min-height: 140px
}
.comment-form textarea:focus {
	border-color: #cecece;
}
/*-----------------------------------------19. Testmonials-------------------------------------------*/

/*--------------Style 1 (Carousel)----------------*/

.testmonial-single {
	padding: 30px 50px;
}
@media (max-width: 767px) {
	.testmonial-single {
		padding: 30px 0px;
	}
}
.testmonial-single p:before {
	margin: auto;
	content: "\f10d";
	font-family: 'Poppins';
	color: #e20b11;
	font-size: 23px;
	display: block;
	margin-bottom: 15px;
}
.testmonial-single p {
	text-align: center;
	font-style: italic;
	color: #666;
	font-size: 17px;
	font-weight: 500;
	line-height: 1.9;
}
.testmonial-single h4 {
	color: #111;
	font-size: 22px;
	font-weight: 500;
	text-align: center;
	margin-top: 20px;
}
.testmonial-single h6 {
	color: #aaa;
	font-size: 15px;
	font-weight: 500;
	text-align: center;
}
/*-------------- Style 2----------------*/

.testmonial-box {
	position: relative;
	background: #fff;
	border-radius: 5px;
	padding: 25px;
	border: 1px solid #ececec;
	margin: 15px 0px;
	-webkit-transition-duration: .3s;
	transition-duration: .3s;
	-webkit-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16);
	-moz-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16);
	box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16);
}
.testmonial-box img {
	border: 2px solid #ececec;
	border-radius: 50%;
	padding: 2px;
}
.testmonial-box h5 {
	color: #111;
	font-size: 18px;
	font-weight: 500;
}
.testmonial-box span {
	color: #999;
	font-size: 13px;
	font-weight: 500;
}
.testmonial-box span:before {
	content: '●';
	color: #e20b11;
	font-size: 15px;
	margin-right: 5px;
}
.testmonial-box p {
	font-size: 15px;
	color: #999;
	line-height: 1.7;
	margin-top: 10px;
}
/*--------------Style 3(Carousel)----------------*/

.testmonial-box-2 {
	position: relative;
	display: block;
	background: #fff;
	border-radius: 5px;
	box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.07);
	padding: 25px;
	margin: 50px 15px 30px 15px;
}
.testmonial-box-2 img {
	width: 80px !important;
	height: 80px;
	border: 3px solid #fff;
	border-radius: 50%;
	margin: -72px 0px 15px 0px;
}
.testmonial-box-2 h4 {
	font-size: 19px;
	font-weight: 500;
	margin-bottom: 0px;
}
.testmonial-box-2 strong {
	font-size: 14px;
	color: #e20b11;
	font-weight: 500;
}
.testmonial-box-2 p {
	font-size: 15px;
	color: #999;
	font-style: italic;
	line-height: 1.6;
	margin-top: 8px;
	margin-bottom: 0px;
}
.testmonial-rating {
	margin-top: 10px;
}
.comming-soon {
    z-index: 100;
    position: absolute;
    color: white;
    font-size: 20px;
    font-weight: bold;
    padding: 10px;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(231, 76, 60,0.8);
}
/*--------------Style 4----------------*/

.testmonial-box-3 {
	padding: 60px 0px 30px 0px;
}
.testmonial-box-3 h4:before {
	content: "\f10d";
	font-family: 'Poppins';
	font-size: 30px;
	color: #e20b11;
	display: block;
	margin-bottom: 15px;
}
.testmonial-box-3 h4 {
	color: #111;
	font-size: 22px;
	font-weight: 600;
}
.testmonial-box-3 h5 {
	font-size: 17px;
	font-weight: 500;
	margin-bottom: 0px;
	margin-top: 20px;
}
.testmonial-box-3 h5 span {
	color: #999;
}
.testmonial-box-3 p {
	font-size: 15px;
	font-style: italic;
	color: #999;
	line-height: 1.7;
	margin-top: 20px;
}
.testmonial-box-3-rating {
	margin-top: 15px;
}
.testmonial-box-3-rating i {
	font-size: 18px;
	color: #e20b11;
}
/*--------------Style 5----------------*/

.testmonial-item {
	position: relative;
	display: inline-block;
}
.testmonial-item-bxx {
	position: relative;
	display: inline-block;
	text-align: center;
	padding: 30px 40px;
	border: 1px solid #eee;
	border-radius: 5px;
	background: #fff;
	margin: 30px 0px;
	-webkit-transition-duration: .3s;
	transition-duration: .3s;
}
.testmonial-arrow {
	position: absolute;
	bottom: -25px;
	left: 50px;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 37px 20px 0 0;
	border-color: #e20b11 transparent transparent transparent;
	display: none;
}
.testmonial-item-bxx-img {
	opacity: 0;
}
.testmonial-item-img {
	display: inline-block;
	margin-left: 10px;
	margin-top: 10px;
}
.testmonial-item-img img {
	border-radius: 100%;
	width: 75px !important;
	height: 75px;
	-webkit-transition-duration: .3s;
	transition-duration: .3s;
}
.testmonial-item-name {
	display: inline-block;
	transform: translateY(-18px);
	margin-left: 15px;
}
.testmonial-item h4 {
	color: #333;
	font-size: 18px;
	font-weight: 500;
	margin: 0px 0px 2px 0px;
}
.testmonial-item span {
	color: #e20b11;
	font-size: 13px;
	font-weight: 500;
}
.testmonial-item p {
	color: #999;
	font-size: 14px;
	text-align: left;
	font-weight: 400;
	line-height: 1.6;
}
.testmonial-box-4-img img {
	width: 150px !important;
	height: 150px;
	border: 3px solid #fff;
	border-radius: 50%;
	margin: 15px auto 0 auto;
}
.testmonial-box-4-text {
	padding: 15px 15px 15px 20px;
}
.testmonial-box-4-text:before {
	position: absolute;
	left: 0px;
	content: "\f10d";
	font-family: 'Poppins';
	font-size: 22px;
	color: #e20b11;
	display: inline-block;
	margin-bottom: 15px;
}
.testmonial-box-4-text p {
	color: #999;
	font-size: 15px;
	font-weight: 400;
	line-height: 1.5;
}
.testmonial-box-4-text h4 {
	color: #333;
	font-size: 20px;
	font-weight: 500;
	margin-top: 20px;
}
.testmonial-box-4-text h5 {
	color: #999;
	font-size: 15px;
	font-weight: 500;
	margin-top: 3px;
}
@media (max-width: 575px) {
	.testmonial-box-4-img {
		margin-left: 10px;
	}
	.testmonial-box-4-img img {
		width: 90px !important;
		height: 90px;
		border: 3px solid #fff;
		border-radius: 50%;
	}
}
.owl-item.center > div .testmonial-item-bxx {
	background: #e20b11;
	border: 1px solid #e20b11;
	cursor: auto;
	box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16);
}
.owl-item.center > div .testmonial-item-bxx-img {
	opacity: 1;
}
.owl-item.center > div .testmonial-arrow {
	display: block;
}
.owl-item.center > div p {
	color: #fff;
}
/*-----------------------------------------20. Cases-------------------------------------------*/

.case-block {
	position: relative;
	background: #fff;
	border: 1px solid #ececec;
	border-radius: 5px;
	overflow: hidden;
	margin: 15px 0px;
	-webkit-transition-duration: .3s;
	transition-duration: .3s;
	-webkit-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16);
	-moz-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16);
	box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16);
}
.case-block:hover {
	-webkit-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.30);
	-moz-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.30);
	box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.30);
}
.case-block img {
	width: 100%;
}
.case-block-inner {
	padding: 25px 25px 20px 10px;
}
@media (max-width: 767px) {
	.case-block-inner {
		padding: 25px;
	}
}
.case-block h4 {
	color: #111;
	font-size: 18px;
	font-weight: 500;
}
.case-block h4:after {
	content: '';
	display: block;
	width: 80px;
	height: 2px;
	background: #e20b11;
	margin-top: 15px;
	margin-bottom: 15px;
	-webkit-transition-duration: .4s;
	transition-duration: .4s;
}
.case-block p {
	color: #aaa;
	font-size: 15px;
	font-weight: 400;
	margin-bottom: 12px;
}
.case-block-inner a {
	font-size: 15px;
	font-weight: 500;
	color: #e20b11;
}
/* .case-block-inner a:after {
	content: '\f0da';
	font-size: 15px;
	font-family: 'Poppins';
	color: #e20b11;
	margin-left: 10px;
	-webkit-transition-duration: .3s;
	transition-duration: .3s;
} */
.case-block-inner a:hover {
	color: #111;
}
.case-block-inner a:hover:after {
	color: #111;
}
/*-----------------------------------------21. Partners (also Team)-------------------------------------------*/

.partner-box {
	position: relative;
	overflow: hidden;
	background: #fff;
	border-radius: 5px;
	-webkit-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16);
	-moz-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16);
	box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16);
	border: 1px solid #ececec;
	margin: 15px 0px;
}
.partner-box img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.partner-img {
	height: 100%;
}
.partner-text {
	padding: 25px 40px 25px 0px;
}
@media (max-width: 991px) {
	.partner-text {
		padding: 25px;
	}
}
.partner-text span {
	color: #e20b11;
	font-size: 13px;
	font-weight: 500;
	letter-spacing: 3px;
	text-transform: uppercase;
}
.partner-box:hover .partner-text span {
	color: #999;
}
.partner-text h4 {
	color: #111;
	font-size: 21px;
	font-weight: 600;
	margin: 5px 0px;
}
.partner-text h4:after {
	content: '';
	display: block;
	width: 40px;
	height: 2px;
	background: #e20b11;
	margin-top: 10px;
	-webkit-transition-duration: .4s;
	transition-duration: .4s;
}
.partner-box:hover .partner-text h4:after {
	width: 80px;
}
.partner-text p {
	color: #999;
	font-size: 15px;
	font-weight: 400;
	margin-top: 15px;
	line-height: 170%;
}
.partner-signature img {
	width: 120px;
	height: 50px;
	margin-top: 15px;
}
/*-----------------------------------------22. Feedback Form-------------------------------------------*/

.feedback-box {
	position: relative;
	background: #f9f9f9;
	border-radius: 5px;
	-webkit-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16);
	-moz-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16);
	box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16);
	border: 1px solid #ececec;
	padding: 25px;
	margin: 10px 0px;
}
.feedback-box h5 {
	color: #555;
	font-size: 14px;
	font-weight: 500;
}
.feedback-box h5:before {
	content: '●';
	color: #e20b11;
	font-size: 16px;
	margin-right: 10px;
}
.feedback-form input {
	color: #111;
	font-weight: 400;
	width: 100%;
	border: 1px solid #ececec;
	border-radius: 5px;
	padding: 12px 15px;
	margin: 15px 0px;
	-webkit-transition-duration: .3s;
	transition-duration: .3s;
}
.feedback-form input:focus {
	border-color: #ccc;
}
.feedback-form textarea {
	color: #111;
	font-weight: 400;
	width: 100%;
	min-height: 100px;
	border: 1px solid #ececec;
	border-radius: 5px;
	padding: 12px 15px;
	margin: 15px 0px;
	-webkit-transition-duration: .3s;
	transition-duration: .3s;
}
.feedback-form textarea:focus {
	border-color: #ccc;
}
.feedback-form button {
	display: inline-block;
	background: #e20b11;
	color: #fff;
	font-size: 14px;
	font-weight: 400;
	padding: 11px 20px 11px 20px;
	border-radius: 50px;
	-webkit-transition: all .4s ease;
	-moz-transition: all .4s ease;
	transition: all .4s ease;
	margin-top: 5px;
}
.feedback-form button:hover {
	background: #111;
}
/*-----------------------------------------23. Team-------------------------------------------*/

/*-------------- Style 1----------------*/

.team-member {
	overflow: hidden;
	background: #fff;
	border: 1px solid #ececec;
	border-radius: 5px;
	-webkit-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16);
	-moz-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16);
	box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16);
	margin: 20px 0px;
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
}
.team-member:hover {
	-webkit-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16);
	-moz-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16);
	box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.28);
}
.team-member-img {
	width: 100%;
	height: auto;
}
.team-member-img img {
	width: 100%;
}
.team-member-text {
	text-align: center;
	padding: 20px 50px;
}
.team-member-text h4 {
	color: #111;
	font-size: 22px;
	font-weight: 500;
	margin-bottom: 3px;
	-webkit-transition-duration: .6s;
	transition-duration: .6s;
}
.team-member:hover .team-member-text h4 {
	color: #e20b11;
}
.team-member-text span {
	color: #e20b11;
	font-size: 15px;
	font-weight: 500;
	letter-spacing: 2px;
}
.team-member-text p {
	color: #999;
	font-size: 14px;
	font-weight: 400;
	line-height: 170%;
	margin-top: 20px;
}
.team-member-text ul {
	margin-top: 15px;
	padding: 8px 0px 8px 0px;
}
.team-member-text ul li {
	display: inline-block;
	padding: 0px 10px;
}
.team-member-text ul li a i {
	color: #555;
	font-size: 17px;
	-webkit-transition-duration: .4s;
	transition-duration: .4s;
}
.team-member-text ul li a i:hover {
	color: #e20b11;
}
@media screen and (max-width: 994px) {
	.team-member-text {
		padding: 20px 10px;
	}
}
/*-------------- Style 2----------------*/

.team-box {
	overflow: hidden;
	background: #fff;
	border-radius: 5px;
	border: 1px solid #ececec;
	margin: 15px 0px;
	padding: 10px;
}
.team-box:hover h4:after {
	width: 90px;
	-webkit-transition-duration: .3s;
	transition-duration: .3s;
}
.team-box img {
	margin-bottom: 5px;
}
.team-info {
	padding: 10px;
}
.team-info span {
	color: #e20b11;
	font-size: 13px;
	font-weight: 500;
	letter-spacing: 4px;
	text-transform: uppercase;
}
.team-info h4 {
	color: #111;
	font-size: 20px;
	font-weight: 500;
	margin: 5px 0px;
}
.team-info h4 a {
	color: #111;
	font-size: 20px;
	font-weight: 500;
	margin: 5px 0px;
}
.team-info h4 a:hover {
	color: #e20b11;
}
.team-info h4:after {
	content: '';
	display: block;
	width: 50px;
	height: 2px;
	background: #e20b11;
	margin: 10px 0px 0px 0px;
	-webkit-transition-duration: .3s;
	transition-duration: .3s;
}
.team-info p {
	color: #aaa;
	font-size: 15px;
	font-weight: 400;
	margin: 15px 0px;
	margin-bottom: 10px;
}
.team-social-icons {
	text-align: left;
}
.team-social-icons ul {
	background: #fff;
	padding: 0px 15px;
	border-radius: 5px;
	margin-bottom: 5px;
}
.team-social-icons ul li {
	display: inline-block;
	padding-right: 20px;
}
.team-social-icons ul li a {
	color: #4b4b4b;
	font-size: 15px;
}
.team-social-icons ul li a:hover {
	color: #e20b11;
}
/*-------------- Team Style 3----------------*/

.team-box-2 {
	position: relative;
}
.team-box-2-image {
	position: relative;
	margin: 15px 0px 15px 0px;
}
.team-box-2-image img {
	display: block;
	width: 100%;
	height: 200px;
	border-radius: 5px;
}
.team-box-2-overlay {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(210, 30, 43, 0.9) 100%);
	background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(210, 30, 43, 0.9) 100%);
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(210, 30, 43, 0.9) 100%);
	overflow: hidden;
	width: 100%;
	height: 100%;
	opacity: 0;
	transition: .5s ease;
}
.team-box-2:hover .team-box-2-overlay {
	opacity: 1;
}
.team-box-2-content {
	white-space: nowrap;
	color: white;
	font-size: 20px;
	position: absolute;
	overflow: hidden;
	top: 90%;
	left: 50%;
	transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	padding: 50px;
}
.team-box-2-content i {
	font-size: 15px;
	text-align: center;
	color: #fff;
	border-radius: 50%;
	background: transparent;
	padding: 10px;
	transition: .6s ease;
}
.team-box-2-content i:hover {
	color: #000;
	background: #fff;
}
.team-box-2:hover .team-box-2-overlay i {
	animation-name: fadeInUp;
	animation-duration: .5s;
	animation-timing-function: ease-out;
}
.team-box-2-name {
	white-space: nowrap;
	color: white;
	font-size: 20px;
	position: absolute;
	overflow: hidden;
	top: 75%;
	left: 50%;
	transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	padding: 50px;
}
.team-box-2-name h4 {
	color: #fff;
	font-size: 20px;
	font-weight: 600;
}
.team-box-2:hover .team-box-2-overlay h4 {
	animation-name: fadeInDown;
	animation-duration: .5s;
	animation-timing-function: ease-out;
}
.team-box-2-name h5 {
	color: #fff;
	font-size: 15px;
	font-weight: 300;
	margin-top: 5px;
	text-align: center;
}
.team-box-2:hover .team-box-2-overlay h5 {
	animation-name: fadeInDown;
	animation-duration: .5s;
	animation-timing-function: ease-out;
}
/*-------------- Team Single----------------*/

.team-single-img {
	width: 100%;
}
.team-single-img img {
	border-radius: 5px;
}
.team-single-text {
	padding: 5px 50px 15px 15px;
}
.team-single-text span {
	color: #e20b11;
	font-size: 14px;
	font-weight: 500;
}
.team-single-text h4 {
	color: #111;
	font-size: 25px;
	font-weight: 500;
	margin-bottom: 10px;
}
.team-single-info {
	padding: 0px 0px;
}
.team-single-info li strong {
	color: #555;
	font-size: 16px;
	font-weight: 500;
	margin-right: 10px;
}
.team-single-text li span {
	color: #777;
	font-size: 15px;
	font-weight: 400;
}
.team-single-social {
	padding: 5px 0px;
}
.team-single-social li {
	display: inline-block;
}
.team-single-social li a {
	display: inline-block;
	margin-right: 15px;
	background: #e20b11;
	width: 35px;
	height: 35px;
	border-radius: 50px;
}
.team-single-social li a:hover {
	display: inline-block;
	margin-right: 15px;
	background: #111;
	width: 35px;
	height: 35px;
	border-radius: 50px;
}
.team-single-social li a i {
	color: #fff;
	font-size: 15px;
	padding: 11px 0px 0px 11px;
}
/*-----------------------------------------24. Video Section-------------------------------------------*/

/*--------------Inner Video Sections----------------*/

.video-section {
	width: 100%;
	height: auto;
}
.video-overlay {
	background: rgba(0, 0, 0, .6);
}
.video-content {
	text-align: center;
	padding: 50px 0px;
}
/*--------------Top Video Section----------------*/

.main-video-section {
	position: relative;
	height: 550px;
}
.main-video-content {
	position: relative;
	text-align: center;
	height: 550px;
	/* Center text vertically */
	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
}
@media (max-width: 767px) {
	.main-video-section {
		height: auto;
	}
	.main-video-content {
		height: auto;
		padding: 50px 0px;
	}
}
.main-video-overlay {
	background: rgba(0, 0, 0, .7);
}
.main-video-content h2 {
	position: relative;
	font-size: 60px;
	font-weight: 400;
	color: #111;
	margin: 5px 0px;
}
@media (max-width: 767px) {
	.main-video-content h2 {
		font-size: 30px;
		font-weight: 400;
	}
}
.main-video-content h3 {
	position: relative;
	font-size: 50px;
	font-weight: 400;
	color: #111;
	margin: 5px 0px;
}
@media (max-width: 767px) {
	.main-video-content h3 {
		font-size: 30px;
		font-weight: 400;
	}
}
.main-video-content h4 {
	position: relative;
	font-size: 40px;
	font-weight: 400;
	color: #111;
	margin: 5px 0px;
}
.main-video-content h5 {
	position: relative;
	font-size: 30px;
	font-weight: 400;
	color: #111;
	margin: 5px 0px;
}
.main-video-content h6 {
	position: relative;
	font-size: 20px;
	font-weight: 400;
	line-height: 1.6;
	color: #111;
	margin: 5px 0px;
}
@media (max-width: 767px) {
	.main-video-content h6 {
		font-size: 15px;
		font-weight: 400;
	}
}
/*-----------------------------------------25. Projects-------------------------------------------*/

.project-grid {
	display: block;
	position: relative;
	margin: 15px 0px;
}
.project-grid-img img {
	display: block;
	width: 100%;
	height: auto;
}
.project-grid-overlay {
	position: absolute;
	bottom: 0;
	left: 2.5%;
	right: 0;
	bottom: 2.6%;
	background: rgba(255, 255, 255, .9);
	overflow: hidden;
	width: 95%;
	height: 95%;
	opacity: 0;
	-webkit-transition-duration: .3s;
	transition-duration: .3s;
}
.project-grid:hover .project-grid-overlay {
	opacity: 1;
}
.project-grid-overlay h4 a {
	color: #111;
	font-size: 22px;
	font-weight: 400;
	position: absolute;
	top: 60%;
	left: 50%;
	opacity: 0;
	transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	text-align: center;
	-webkit-transition-duration: .5s;
	transition-duration: .5s;
}
.project-grid-overlay h4 a:hover {
	color: #e20b11;
}
.project-grid-overlay h4 a:after {
	content: '';
	display: block;
	width: 70px;
	height: 1px;
	background: #e20b11;
	margin-top: 10px !important;
	margin-bottom: 15px !important;
	margin: 0 auto;
}
.project-grid:hover h4 a {
	top: 40%;
	opacity: 1;
}
.project-grid-overlay p {
	color: #666;
	font-size: 15px;
	font-weight: 400;
	position: absolute;
	top: 70%;
	left: 50%;
	opacity: 0;
	transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	text-align: center;
	-webkit-transition-duration: .6s;
	transition-duration: .6s;
}
.project-grid:hover p {
	top: 50%;
	opacity: 1;
}
.project-grid-overlay span {
	color: #999;
	font-size: 16px;
	font-weight: 500;
	position: absolute;
	writing-mode: vertical-rl;
	writing-mode: tb-rl;
	letter-spacing: 4px;
	top: 10%;
	left: 90%;
}
@media screen and (max-width: 767px) {
	.project-grid:hover p {
		top: 52%;
	}
}
@media screen and (max-width: 1201px) {
	.project-grid-overlay {
		bottom: 0;
		left: 0px;
		right: 0;
		bottom: 0px;
		width: 100%;
		height: 100%;
	}
}
/*-----------------------------------------26. Project Single-------------------------------------------*/

/*-------------- Style 1----------------*/

.project-single-img {
	padding-bottom: 30px;
}
.project-single-text {
	margin: 30px 0px;
}
.project-single-text h4 {
	color: #111;
	font-size: 22px;
	font-weight: 500;
	margin-bottom: 25px;
}
.project-single-text h4:after {
	content: " ";
	display: block;
	background: #e20b11;
	width: 70px;
	height: 2px;
	margin-top: 8px;
}
.project-single-text p {
	color: #999;
	font-size: 15px;
	font-weight: 400;
	line-height: 1.8;
	margin: 15px 0px;
}
.project-single-text h5 {
	color: #111;
	font-size: 18px;
	font-weight: 500;
	margin: 25px 0px 20px 0px;
}
.project-single-text h5:after {
	content: " ";
	display: block;
	background: #e20b11;
	width: 70px;
	height: 2px;
	margin-top: 8px;
}
.project-single-text ul li {
	color: #111;
	font-size: 15px;
	font-weight: 500;
	padding: 8px 0px;
}
.project-single-text ul li i {
	color: #e20b11;
	font-weight: 500;
	font-size: 15px;
	padding-right: 10px;
}
/*Right*/

.project-single-info {
	display: inline-block;
	border: 1px solid #ececec;
	display: block;
	width: 100%;
	padding: 0px 20px;
}
.project-single-info ul li {
	color: #777;
	font-size: 15px;
	font-weight: 500;
	border-bottom: 1px solid #ececec;
	padding: 17px 0px;
}
.project-single-info ul li:last-child {
	border-bottom: none;
}
.project-single-info ul li span {
	display: inline-block;
	color: #111;
	font-size: 15px;
	font-weight: 500;
	margin-right: 25px;
	width: 70px;
	max-width: 100%;
}
/*carousel*/

.project-carousel {
	margin: 40px 0px 20px;
}
.project-carousel h4 {
	color: #111;
	font-size: 22px;
	font-weight: 500;
	margin: 25px 0px 20px 0px;
}
.project-carousel h4:after {
	content: " ";
	display: block;
	background: #e20b11;
	width: 70px;
	height: 2px;
	margin-top: 8px;
}
.project-item {
	position: relative;
	overflow: hidden;
}
.project-item img {
	width: 100%;
	border-radius: 5px;
}
.project-item-overlay {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	height: 100%;
	width: 100%;
	opacity: 0;
	z-index: 999;
	transition: .5s ease;
	background: rgba(0, 0, 0, 0.8);
	border-radius: 5px;
}
.project-item:hover .project-item-overlay {
	opacity: 1;
}
.project-item-content {
	position: absolute;
	bottom: 30px;
	left: 30px;
}
@media screen and (max-width: 767px) {
	.project-item-content {
		position: absolute;
		bottom: 15px;
		left: 15px;
	}
}
.project-item-content span {
	font-size: 13px;
	font-weight: 400;
	color: #fff;
	opacity: .6;
}
.project-item-content h6 {
	font-size: 18px;
	font-weight: 400;
	color: #fff;
	margin-top: 5px;
}
.project-item-content a {
	display: inline-block;
	font-size: 13px;
	font-weight: 400;
	background: #e20b11;
	color: #fff;
	border: 1.5px solid #e20b11;
	border-radius: 5px;
	margin-top: 10px;
	padding: 10px 15px 10px 15px;
}
.project-item-content a:hover {
	border: 1.5px solid #fff;
	background: transparent;
	color: #fff;
}
.project-detail img {
	width: 100%;
	height: 500px;
	border-radius: 5px;
	margin-bottom: 30px;
}
/*-------------- Style 2----------------*/

.project-single-box-img img {
	width: 100%;
	border-radius: 10px;
	-webkit-box-shadow: 0 20px 40px rgba(0, 0, 0, 0.07);
	box-shadow: 0 20px 40px rgba(0, 0, 0, 0.07);
}
.project-info-shortcode {
	border-bottom: 1px solid #ececec;
	padding: 15px 0px;
}
.project-info-shortcode:last-child {
	border-bottom: none;
}
.project-single-box-info {
	position: relative;
	border-radius: 10px;
	background: #f9f9f9;
}
.project-info-shortcode-icon {
	text-align: center;
	padding: 15px 0px;
}
.project-info-shortcode-icon i {
	color: #e20b11;
	font-size: 20px;
}
.project-info-shortcode-text {
	position: relative;
}
.project-info-shortcode-text h5 {
	color: #111;
	font-size: 17px;
	font-weight: 500;
	margin-top: 7px;
}
.project-info-shortcode-text p {
	color: #999;
	font-size: 15px;
	font-weight: 400;
}
@media screen and (max-width: 767px) {
	.project-info-shortcode-icon i {
		color: #e20b11;
		font-size: 30px;
		padding: 0px 30px;
	}
	.project-info-shortcode-text {
		text-align: center;
		padding: 0px 15px 0px 30px;
	}
}
/*-----------------------------------------27. Accordions-------------------------------------------*/

.accordion {
	background: transparent;
	box-shadow: none;
	border: none;
	border-bottom: 1px solid #ececec;
	border-radius: 0px !important;
}
.accordion-heading {
	border-radius: 0px;
	padding: 0px;
}
.accordion-title a {
	font-size: 20px;
	font-weight: 600;
	color: #fff!important;
	line-height: 1;
	display: block;
	padding: 20px 0px 25px 0px;
	border-radius: 0px;
}
.accordion-title a:after {
	content: '\f107';
	font-size: 18px;
	font-family: 'Poppins';
	color: #e20b11;
	float: right;
}
.accordion-title .collapsed:after {
	content: '\f105';
	font-size: 18px;
	font-family: 'Poppins';
	color: #333;
	float: right;
}
.accordion-body {
	font-size: 15px;
	font-weight: 400;
	color: #999;
	line-height: 170%;
	text-align: left;
	border: none !important;
	padding: 0px;
	padding: 18px 0px 30px 0px;
}
.accordion-body p {
	margin-bottom: 0px;
}
.panel-body {
	border-top: 1px solid #eee !important;
}
.panel-default > .panel-heading {
	color: #333;
	background-color: transparent !important;
	border-color: #ddd;
}
/*Grey Accordions*/

.panel-grey > .panel-heading a {
	color: #111;
	background-color: #db0000 !important;
	border: none !important;
	border-radius: 5px;
	padding-left: 15px;
	padding-right: 15px;
	margin-bottom: 5px;
}
.panel-grey {
	border: none !important;
}
.panel-grey .panel-body {
	border: none !important;
}
.panel-grey .accordion-title a:after {
	content: '\f056';
	font-size: 18px;
	font-family: 'Poppins';
	color: #e20b11;
	float: right;
}
.panel-grey .accordion-title .collapsed:after {
	content: '\f055';
	font-size: 18px;
	font-family: 'Poppins';
	color: #333;
	float: right;
}
/*-----------------------------------------28. Service Single-------------------------------------------*/

.services-single-menu {
	border: 1px solid #ececec;
}
.services-single-menu ul li {
	border-bottom: 1px solid #ececec;
	background: #fff;
}
.services-single-menu ul li:last-child {
	border-bottom: none;
}
.services-single-menu ul li:hover {
	background: #f9f9f9;
}
.services-single-menu ul li a {
	display: block;
	color: #111;
	font-size: 15px;
	font-weight: 500;
	padding: 17px 0px 17px 15px;
	-webkit-transition-duration: .3s;
	transition-duration: .3s;
}
.services-single-menu ul li a:hover {
	-webkit-box-shadow: 3px 0px 0px 0px rgba(210, 30, 43, 1) inset;
	-moz-box-shadow: 3px 0px 0px 0px rgba(210, 30, 43, 1) inset;
	box-shadow: 3px 0px 0px 0px rgba(210, 30, 43, 1) inset;
}
.services-active {
	-webkit-box-shadow: 3px 0px 0px 0px rgba(210, 30, 43, 1) inset;
	-moz-box-shadow: 3px 0px 0px 0px rgba(210, 30, 43, 1) inset;
	box-shadow: 3px 0px 0px 0px rgba(210, 30, 43, 1) inset;
}
.services-single-left-heading h4 {
	color: #111;
	font-size: 18px;
	font-weight: 500;
}
.services-single-left-heading h4:after {
	content: " ";
	display: block;
	background: #e20b11;
	width: 50px;
	height: 2px;
	margin-top: 8px;
}
.section-single-heading h4 {
	color: #111;
	font-size: 20px;
	font-weight: 500;
}
.section-single-heading h4:after {
	content: " ";
	display: block;
	background: #e20b11;
	width: 60px;
	height: 2px;
	margin-top: 8px;
}
.chartjs-render-monitor {
	margin-top: 20px;
	display: block;
	height: auto;
	width: 100%;
}
.download-file-button {
	background: #f8f7f6;
	border-radius: 5px;
	margin: 40px 0px;
	padding: 25px 50px;
	-webkit-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16);
	-moz-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16);
	box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16);
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
}
.download-file-button:hover {
	-webkit-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16);
	-moz-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16);
	box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.28);
}
.download-file-button h5 {
	display: inline-block;
	color: #111;
	font-size: 20px;
	font-weight: 400;
	margin-top: 8px;
}
.download-file-button h5 span {
	color: #888;
	font-size: 18px;
	font-weight: 400;
	margin-left: 10px;
}
.download-file-button i {
	font-family: 'Poppins';
	display: inline-block;
	float: right;
	font-size: 40px;
	color: #e20b11;
}
/*-----------------------------------------29. Callback-------------------------------------------*/

/*Style 1*/

.callback-box {
	position: relative;
	background: #f7f7f7;
	border: 1px solid #ececec;
	border-radius: 5px;
	padding: 25px 15px;
}
.callback-box-form input {
	width: 100%;
	background: #fff;
	color: #111;
	font-weight: 400;
	padding: 12px 10px;
	border: none;
	border-radius: 5px;
	margin: 5px 0px;
}
.callback-box-form button {
	display: block;
	width: 100%;
	background: #e20b11;
	color: #fff;
	font-size: 13px;
	font-weight: 500;
	border-radius: 50px;
	margin-top: 5px;
	padding: 10px 15px 10px 15px;
	-webkit-transition: all .4s ease;
	-moz-transition: all .4s ease;
	transition: all .4s ease;
}
.callback-box-form button:hover {
	background: #111;
}
/*Style 2*/

.callback-form input {
	position: relative;
	display: block;
	width: 100%;
	background: #fff;
	color: #111;
	font-weight: 400;
	padding: 16px 16px;
	border: none;
	border-radius: 5px;
	margin: 15px 0px;
}
/*-----------------------------------------30. Projects Masonry-------------------------------------------*/


.masonry {
	margin: 10px;
	padding: 0;
	-moz-column-gap: 10px;
	-webkit-column-gap: 10px;
	column-gap: 10px;
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
}
.masonry a {
	display: block;
	margin-top: 10px;
}
.masonry a:first-child {
	margin-top: 0px;
}
.masonry-item {
	position: relative;
	overflow: hidden;
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
}
.masonry-item img {
	display: block;
	width: 100%;
	height: auto;
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
}
.masonry-item-overlay {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	height: 100%;
	max-height: 100%;
	width: 100%;
	opacity: 1;
	background: rgba(0, 0, 0, 0.3);
	padding: 30px;
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
}
.masonry-item:hover img {
	transform: scale(1.2);
}
.masonry-item:hover .masonry-item-overlay {
	position: absolute;
	opacity: 0;
	-webkit-transition-duration: .5s;
	transition-duration: .5s;
}
.masonry-item:hover .masonry-item-overlay h4 {
	opacity: 0;
	transform: translate(0%, -50%);
	-ms-transform: translate(0%, -50%);
	text-align: center;
	-webkit-transition-duration: .7s;
	transition-duration: .7s;
}
.masonry-item:hover .masonry-item-overlay ul {
	opacity: 0;
	transform: translate(0%, -50%);
	-ms-transform: translate(0%, -50%);
	text-align: center;
	-webkit-transition-duration: .5s;
	transition-duration: .5s;
}
.masonry-item-overlay h4 {
	color: #fff;
	font-size: 22px;
	font-weight: 400;
	position: absolute;
	bottom: 50px;
}
.masonry-item-overlay ul {
	position: absolute;
	padding-left: 0px;
	bottom: 25px;
}
.masonry-item-overlay ul li {
	list-style-type: none;
	display: inline-block;
	font-size: 12px;
	font-weight: 600;
	color: #fff;
	padding-left: 0px;
	opacity: 0.7;
}
.masonry-item-overlay ul li:last-child:after {
	content: none;
}
.masonry-item-overlay ul li:after {
	content: ' / ';
	font-size: 8px;
	font-weight: 700;
	margin: 0px 5px 0px 5px;
}
.masonry-item-overlay ul li a {
	color: #fff;
}
.masonry-item:first-child {
	margin-top: 0px;
}
@media only screen and (min-width: 400px) {
	.masonry {
		-moz-column-count: 2;
		-webkit-column-count: 2;
		column-count: 2;
	}
}
@media only screen and (min-width: 700px) {
	.masonry {
		-moz-column-count: 3;
		-webkit-column-count: 3;
		column-count: 3;
	}
}
@media only screen and (min-width: 900px) {
	.masonry {
		-moz-column-count: 2;
		-webkit-column-count: 2;
		column-count: 2;
	}
}
@media only screen and (min-width: 1100px) {
	.masonry {
		-moz-column-count: 3;
		-webkit-column-count: 3;
		column-count: 3;
	}
}
/*4 columns*/

.masonry-4 {
	margin: 10px;
	padding: 0;
	-moz-column-gap: 10px;
	-webkit-column-gap: 10px;
	column-gap: 10px;
}
.masonry-4 a {
	display: block;
	margin-top: 10px;
}
.masonry-4 a:first-child {
	margin-top: 0px;
}
@media only screen and (min-width: 400px) {
	.masonry-4 {
		-moz-column-count: 2;
		-webkit-column-count: 2;
		column-count: 2;
	}
}
@media only screen and (min-width: 700px) {
	.masonry-4 {
		-moz-column-count: 3;
		-webkit-column-count: 3;
		column-count: 3;
	}
}
@media only screen and (min-width: 900px) {
	.masonry-4 {
		-moz-column-count: 2;
		-webkit-column-count: 2;
		column-count: 2;
	}
}
@media only screen and (min-width: 1100px) {
	.masonry-4 {
		-moz-column-count: 4;
		-webkit-column-count: 4;
		column-count: 4;
	}
}
/*-----------------------------------------31. Under Construction-------------------------------------------*/

.count-back-box {
	width: 100%;
	padding: 100px 0px;
	text-align: center;
	background-position: center;
	background-size: cover;
}
.count-back-box h1 {
	color: #111;
	font-size: 40px;
	font-weight: 500;
}
.construction-box {
	margin: auto;
}
.construction-icons {
	position: relative;
	text-align: center;
}
.construction-icons i {
	display: inline-block;
	color: #e20b11;
}
#cons-icon-1 {
	font-size: 120px;
	animation: construction-rotate 5s linear infinite;
}
#cons-icon-2 {
	font-size: 80px;
	animation: construction-rotate-back 5s linear infinite;
	margin-left: -9px;
}
@keyframes construction-rotate {
	0% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(360deg);
	}
}
@keyframes construction-rotate-back {
	0% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(-360deg);
	}
}
.timer {
	/* font-family: "Segment7Standard"; */
	font-size: 70px;
	display: inline-block;
	vertical-align: top;
}
.clock {
	margin-top: 30px;
}
.clock h5 {
	color: #e20b11;
	font-size: 16px;
	font-weight: 400;
	margin-left: 15px;
}
.clock .column {
	display: inline-block;
}
.column {
	padding: 0px;
}
.count-back-box-text {
	text-align: center;
	margin: 30px auto 0 auto;
}
.count-back-box-text h3 {
	color: #111;
	font-size: 30px;
	font-weight: 400;
	line-height: 170%;
}
.count-back-box-text h6 {
	color: #777;
	font-size: 18px;
	font-weight: 400;
	margin-top: 10px;
}
@media (max-width: 767px) {
	.timer {
		font-size: 30px;
	}
	#cons-icon-1 {
		font-size: 65px;
	}
}
/*-----------------------------------------32. Shop Single Slider-------------------------------------------*/

.sr-text {
	position: absolute !important;
	top: -9999px !important;
	left: -9999px !important;
}
.slick-slider .slick-prev,
.slick-slider .slick-next {
	display: none !important;
	z-index: 100;
	font-size: 2.5em;
	height: 40px;
	width: 40px;
	margin-top: -20px;
	color: #eee;
	position: absolute;
	top: 50%;
	text-align: center;
	color: #222;
	opacity: 1;
	transition: opacity .25s;
	cursor: pointer;
	background: #eee;
}
.slick-slider .slick-prev:hover,
.slick-slider .slick-next:hover {
	opacity: .65;
}
.slick-slider .slick-prev {
	left: 0;
}
.slick-slider .slick-next {
	right: 0;
}
.product-images {
	width: 100%;
	margin: 0 auto;
}
.product-images li,
.product-images figure,
.product-images a,
.product-images img {
	display: block;
	outline: none;
	border: none;
}
.product-images .main-img-slider figure {
	margin: 0 auto;
}
.product-images .main-img-slider figure a {
	cursor: e-resize;
}
.product-images .main-img-slider figure a img {
	width: 100%;
	margin: 0 auto;
}
.product-images .thumb-nav {
	margin: 0 auto;
	margin-top: 0.5px;
	width: 100%;
	padding: 0;
}
.product-images .thumb-nav.slick-slider .slick-prev,
.product-images .thumb-nav.slick-slider .slick-next {
	font-size: 1.2em;
	height: 20px;
	width: 26px;
	margin-top: -10px;
}
.product-images .thumb-nav.slick-slider .slick-prev {
	margin-left: -30px;
}
.product-images .thumb-nav.slick-slider .slick-next {
	margin-right: -30px;
}
.product-images .thumb-nav li {
	display: block;
	margin: 0 auto;
	float: left;
	cursor: pointer;
	margin-right: 0px;
	-webkit-transition-duration: .3s;
	transition-duration: .3s;
}
.product-images .thumb-nav li img {
	display: block;
	width: 100%;
	max-width: 250px;
	margin: 0 auto;
	border: 0.5px solid transparent;
	border-bottom: 2px solid transparent;
}
.product-images .thumb-nav li:hover {
	opacity: .7;
}
.product-images .thumb-nav li:hover,
.product-images .thumb-nav li:focus {
	border-color: #ccc;
}
.product-images .thumb-nav li.slick-current img {
	border-bottom: 2px solid #e20b11;
}
.pswp__bg {
	background: #fff;
}
.pswp__top-bar {
	background-color: transparent !important;
}
.pswp__button,
.pswp__button:before,
.pswp__button--close:before,
.pswp__button--arrow--left:before,
.pswp__button--arrow--right:before {
	background: none !important;
	background-size: 100%;
	width: 44px;
	height: 44px;
	/* font-family: 'icomoon'; */
}
.pswp__button {
	color: #000 !important;
	opacity: 0.4 !important;
	transition: opacity .25s;
}
.pswp__button:hover {
	opacity: 0.65 !important;
}
.pswp__button:before {
	opacity: 1 !important;
}
.pswp__button.pswp__button--arrow--left:before,
.pswp__button.pswp__button--arrow--right:before {
	font-size: 44px;
}
.pswp__button.pswp__button--arrow--left:before {
	content: "\e93b";
}
.pswp__button.pswp__button--arrow--right:before {
	content: "\e93c";
}
.pswp__button.pswp__button--close {
	top: 10px;
	right: 20px;
}
.pswp__button.pswp__button--close:before {
	content: "\e92a";
	font-size: 44px;
}
.pswp__button.pswp__button--close:hover {
	color: #222;
}
/*-----------------------------------------33. Shop Single-------------------------------------------*/

.single-product {
	padding-left: 30px;
}
@media screen and (max-width: 767px) {
	.single-product {
		padding-left: 0px;
		margin-top: 30px;
	}
}
.single-product h2 {
	font-size: 31px;
	font-weight: 500;
	color: #111;
}
.single-product p {
	font-size: 15px;
	font-weight: 400;
	line-height: 180%;
	color: #aaa;
	margin-top: 20px;
}
.product-review-area {
	margin-top: 15px;
}
.product-review-area i {
	color: #f9d100;
	font-size: 18px;
	margin-right: 2px;
}
.product-review-area span {
	color: #111;
	font-size: 14px;
	margin-left: 10px;
}
.single-product-price {
	margin-top: 20px;
}
.single-product-price h4 {
	font-size: 25px;
	font-weight: 500;
	color: #111;
}
.product-categories {
	display: block;
	margin-top: 35px;
	border-top: 1px solid #eee;
	padding: 20px 5px 0px 5px;
}
.product-categories ul {
	display: block;
}
.product-categories span {
	font-size: 15px;
	font-weight: 500;
	color: #111;
	line-height: 2;
}
.product-categories ul {
	display: inline-block;
	padding-left: 20px;
	margin-bottom: 0px;
}
.product-categories ul li {
	list-style-type: none;
	display: inline-block;
	color: #aaa;
	font-weight: 500;
}
.product-categories ul li a {
	color: #aaa;
	font-size: 14px;
}
.product-categories ul li a:hover {
	color: #e20b11;
}
.product-categories ul li:after {
	content: ' / ';
	display: inline-block;
	font-size: 10px;
	color: #aaa;
	margin: 0px 5px;
}
.product-categories ul li:last-child:after {
	content: none;
}
.product-tags {
	display: block;
	padding: 0px 5px;
}
.product-tags ul {
	display: block;
}
.product-tags span {
	font-size: 15px;
	font-weight: 500;
	color: #111;
	line-height: 2;
}
.product-tags ul {
	display: inline-block;
	padding-left: 20px;
	margin-bottom: 0px;
}
.product-tags ul li {
	list-style-type: none;
	display: inline-block;
	color: #aaa;
	font-weight: 500;
}
.product-tags ul li a {
	color: #aaa;
	font-size: 14px;
}
.product-tags ul li a:hover {
	color: #e20b11;
}
.product-tags ul li:after {
	content: ', ';
	display: inline-block;
	font-size: 10px;
	color: #aaa;
	margin: 0px 5px;
}
.product-tags ul li:last-child:after {
	content: none;
}
.quantity {
	position: relative;
}
.quantity input[type=number]::-webkit-inner-spin-button,
.quantity input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
.quantity input[type=number] {
	-moz-appearance: textfield;
}
.quantity input {
	width: 85px;
	height: 62px;
	line-height: 1.65;
	font-size: 16px;
	color: #111;
	font-weight: 600;
	float: left;
	display: block;
	padding: 0;
	margin: 0;
	padding-left: 20px;
	border: 2px solid #eee;
}
.quantity input:focus {
	outline: 0;
}
.quantity-nav {
	float: left;
	position: relative;
	height: 62px;
}
.quantity-button {
	position: relative;
	cursor: pointer;
	width: 30px;
	text-align: center;
	color: #111;
	background: #fff;
	border: 2px solid #ececec;
	font-size: 17px;
	/* font-family: "Trebuchet MS", Helvetica, sans-serif !important; */
	line-height: 1.7;
	-webkit-transform: translateX(-100%);
	transform: translateX(-100%);
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
}
.quantity-button.quantity-up {
	position: absolute;
	height: 50%;
	top: 0;
}
.quantity-button.quantity-down {
	position: absolute;
	bottom: 0px;
	height: 50%;
	border-top: none;
}
.add-to-cart-button {
	display: inline-block;
	background: #e20b11;
	color: #fff;
	font-size: 17px;
	font-weight: 600;
	padding: 19px 30px;
	margin-left: 10px;
	border-radius: 5px;
	-webkit-transition-duration: .3s;
	transition-duration: .3s;
}
.add-to-cart-button:hover {
	background: #111;
	color: #fff;
}
.product-share {
	background: #f7f7f7;
	border-radius: 5px;
	padding: 20px 15px;
}
.product-share span {
	font-size: 15px;
	font-weight: 500;
	color: #111;
	margin-right: 15px;
}
.product-share a i {
	color: #111;
	font-size: 15px;
	padding: 0px 7px;
	-webkit-transition-duration: .3s;
	transition-duration: .3s;
}
.product-share a i:hover {
	color: #e20b11;
}
/*---------------Shop Tabs-----------------*/

.shop-tabs .tabs {
	position: relative;
}
.shop-tabs .tabs .tab-menu li {
	background: #f7f7f7;
	display: inline-block;
	width: 33.11%;
	text-align: center;
}
.shop-tabs .tabs .tab-menu li a {
	color: #111;
	display: block;
	font-size: 15px;
	font-weight: 500;
	padding: 18px 24px;
	text-decoration: none;
}
.shop-tabs .tabs .tab-menu li:hover a,
.tabs li:hover i {
	color: #e20b11;
}
.shop-tabs .tab-menu .active-tab a,
.shop-tabs .active-tab a i {
	color: #e20b11 !important;
}
.shop-tabs .tab-menu {
	display: flex;
	justify-content: space-between;
	margin-bottom: 4px;
}
@media screen and (max-width: 1200px) {
	.shop-tabs .tabs .tab-menu li {
		background: #f7f7f7;
		display: inline-block;
		text-align: center;
		width: auto;
	}
	.shop-tabs .tabs .tab-menu li a {
		color: #111;
		display: block;
		font-size: 15px;
		font-weight: 500;
		padding: 18px 24px;
		text-decoration: none;
	}
}
@media screen and (max-width: 767px) {
	.shop-tabs .tabs .tab-menu li {
		display: block;
		width: 100%;
		text-align: center;
		margin-bottom: 4px;
	}
	.shop-tabs .tab-menu .tabs li a {
		display: block;
	}
	.shop-tabs .tab-menu .tabs li i {
		font-size: 25px;
	}
}
.product-tab-body {
	display: block;
	position: relative;
	padding: 25px;
	background: #f7f7f7 !important;
}
.product-tab-body h2 {
	font-size: 30px;
	color: #111;
	font-weight: 300;
	margin-bottom: 10px;
}
.product-tab-body h3 {
	font-size: 25px;
	color: #111;
	font-weight: 500;
	margin-bottom: 10px;
}
.product-tab-body h4 {
	font-size: 20px;
	color: #111;
	font-weight: 500;
	margin-bottom: 10px;
}
.product-tab-body h5 {
	font-size: 15px;
	color: #111;
	font-weight: 500;
	margin-bottom: 10px;
}
.product-tab-body h2:after,
.product-tab-body h3:after,
.product-tab-body h4:after,
.product-tab-body h5:after {
	content: '';
	display: block;
	width: 100px;
	height: 2px;
	background: #e20b11;
	margin-top: 10px;
	margin-bottom: 10px;
}
.product-tab-body ul {
	margin-top: 15px;
}
.product-tab-body ul li {
	display: block;
	font-size: 15px;
	color: #111;
	border-bottom: 1px dashed #ddd;
	line-height: 1.9;
	font-weight: 500;
	padding: 15px 5px;
}
.product-tab-body ul li span {
	display: inline-block;
	width: 200px;
	max-width: 100%;
}
.product-tab-body ul li:before {
	content: "●";
	display: inline-block;
	color: #e20b11;
	font-size: 14px;
	margin-right: 10px;
	line-height: 1;
}
.product-tab-body p {
	font-size: 15px;
	color: #555;
	line-height: 1.9;
	font-weight: 400;
	margin: 15px 0px;
}
/*-------------------Shop Product Review Form---------------------*/

.shop-form-title {
	font-size: 15px;
	color: #111;
	font-weight: 500;
	line-height: 1;
}
.rating {
	display: inline-block;
	position: relative;
	height: 25px;
	font-size: 25px;
}
.rating label {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	cursor: pointer;
	line-height: 1;
}
.rating label:last-child {
	position: static;
}
.rating label:nth-child(1) {
	z-index: 5;
}
.rating label:nth-child(2) {
	z-index: 4;
}
.rating label:nth-child(3) {
	z-index: 3;
}
.rating label:nth-child(4) {
	z-index: 2;
}
.rating label:nth-child(5) {
	z-index: 1;
}
.rating label input {
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
}
.rating label .icon {
	float: left;
	color: transparent;
}
.rating label:last-child .icon {
	color: #ccc;
}
.rating:not(:hover) label input:checked ~ .icon,
.rating:hover label:hover input ~ .icon {
	color: #f9d100;
}
.rating label input:focus:not(:checked) ~ .icon:last-child {
	color: #ccc;
	text-shadow: 0 0 5px #f9d100;
}
.review-input input {
	font-weight: 400;
	font-size: 14px;
	color: #111;
	display: block;
	margin-top: 15px;
	border-radius: 5px;
	border: 1px solid #eee;
	padding: 13px 15px;
	background: #fff;
	width: 100%;
	max-width: 100%;
	transition: .3s;
}
.review-input input:focus {
	border-color: #ccc;
}
.review-input textarea {
	font-weight: 400;
	font-size: 14px;
	color: #111;
	display: block;
	margin-top: 15px;
	border-radius: 5px;
	border: 1px solid #eee;
	padding: 13px 15px;
	background: #fff;
	width: 100%;
	min-height: 100px;
	transition: .3s;
}
.review-input textarea:focus {
	border-color: #ccc;
}
.review-input button {
	display: inline-block;
	background: #111;
	color: #fff;
	font-size: 14px;
	font-weight: 500;
	border-radius: 4px;
	padding: 12px 35px 12px 35px;
	-webkit-transition: all .4s ease;
	-moz-transition: all .4s ease;
	transition: all .4s ease;
	margin-top: 20px;
}
.review-input button:hover {
	background: #e20b11;
}
.customer-review {
	margin-top: 30px;
}
.customer-review-block {
	background: #fff;
	border-radius: 5px;
	border: 1px solid #eee;
	padding: 20px;
}
.customer-review-block h5 {
	display: inline-block;
	color: #111;
	font-size: 17px;
	font-weight: 600;
	line-height: 1;
	margin: 0px;
}
.customer-review-block h5:after {
	content: none;
}
.customer-review-block strong {
	display: inline-block;
	color: #e20b11;
	font-size: 13px;
	font-weight: 500;
	line-height: 1;
	margin-left: 15px;
}
.customer-review-block p {
	font-size: 15px;
	color: #888;
	line-height: 1.9;
	font-weight: 400;
	margin-bottom: 15px;
}
.customer-review-stars {
	text-align: center;
	margin: 10px 0px;
}
.customer-review-stars i {
	color: #f9d100;
	font-size: 13.5px;
}
/*-----------------------------------------34. Shop Products-------------------------------------------*/

/*----------- Grid Style-------------*/

.shop-grid {
	border: 1px solid #ececec;
	border-radius: 5px;
	background: #fff;
	overflow: hidden;
	padding: 15px;
	margin: 20px 0px;
	-webkit-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16);
	-moz-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16);
	box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.08);
	-webkit-transition-duration: 0.5s;
	transition-duration: 0.5s;
}
.shop-grid:hover {
	-webkit-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16);
	-moz-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16);
	box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.20);
}
.shop-grid-img {
	position: relative;
	overflow: hidden;
	width: 100%;
}
.shop-grid-img img {
	width: 100%;
	height: auto;
}
.shop-grid:hover .shop-grid-img img {
	transform: scale(1.06);
}
.shop-grid-info {
	padding: 5px 0px 20px 0px;
}
.shop-grid-info h4 {
	color: #111;
	font-size: 18px;
	font-weight: 400;
	margin-bottom: 3px;
}
.shop-grid-info h4 a {
	color: #111;
	-webkit-transition-duration: 0.5s;
	transition-duration: 0.5s;
}
.shop-grid-info h4 a:hover {
	color: #e20b11;
}
.shop-grid-info span {
	color: #ccc;
	font-size: 14px;
	font-weight: 400;
	letter-spacing: 1px;
}
.shop-grid-info h5 {
	color: #e20b11;
	font-size: 22px;
	font-weight: 400;
	text-align: right;
	margin-top: 7px;
	word-break: break-word;
	-webkit-transition-duration: 0.5s;
	transition-duration: 0.5s;
}
.shop-grid:hover .shop-grid-info h5 {
	color: #e20b11;
}
/*-----------------Grid Classic Style-------------------*/

.classic-shop {
	border: 1px solid #ececec;
	border-radius: 5px;
	-webkit-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16);
	-moz-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16);
	box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.10);
	margin: 20px 0px;
	-webkit-transition-duration: 0.5s;
	transition-duration: 0.5s;
}
.classic-shop:hover {
	-webkit-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16);
	-moz-box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.16);
	box-shadow: 0px 10px 30px 0px rgba(50, 50, 50, 0.20);
}
.classic-shop-img {
	position: relative;
	overflow: hidden;
	width: 100%;
}
.classic-shop-img img {
	display: block;
	width: 100%;
	height: auto;
}
.shop-box-overlay {
	position: absolute;
	bottom: 0;
	width: 100%;
	text-align: center;
}
.classic-shop:hover .classic-shop-img img {
	transform: scale(1.06);
}
.shop-box-overlay h4 {
	color: #fff;
	font-size: 15px;
	font-weight: 500;
	letter-spacing: 1px;
	-webkit-transition-duration: 0.5s;
	transition-duration: 0.5s;
}
.shop-box-overlay h4 span {
	color: #fff;
	font-size: 15px;
	margin-right: 10px;
	-webkit-transition-duration: 0.5s;
	transition-duration: 0.5s;
}
.shop-to-card {
	background: #e20b11;
	padding: 15px 5px 15px 5px;
	-webkit-transition-duration: 0.5s;
	transition-duration: 0.5s;
}
.shop-to-card:hover {
	background: rgba(255, 255, 255, 0.5)
}
.shop-to-card:hover h4 {
	color: #e20b11;
}
.shop-to-card:hover h4 span {
	color: #111;
}
.shop-buy {
	background: #111;
	padding: 15px 5px 15px 5px;
	-webkit-transition-duration: 0.5s;
	transition-duration: 0.5s;
}
.shop-buy:hover {
	background: rgba(255, 255, 255, 0.5)
}
.shop-buy:hover h4 {
	color: #e20b11;
}
.shop-buy:hover h4 span {
	color: #111;
}
.classic-shop-text {
	text-align: center;
	padding: 15px 0;
}
.classic-shop-text h4 a {
	color: #111;
	font-size: 22px;
	font-weight: 500;
	-webkit-transition-duration: 0.5s;
	transition-duration: 0.5s;
}
.classic-shop:hover .classic-shop-text h4 a {
	color: #e20b11;
}
.classic-shop-text h5 {
	color: #e20b11;
	font-size: 18px;
	font-weight: 500;
	margin-top: 7px;
}
.shop-grid-rating {
	margin-top: 15px;
}
.shop-grid-rating li {
	display: inline-block;
}
.shop-grid-rating li i {
	color: #111;
	font-size: 17px;
	margin: 0px 1px;
}
/*----------- Shop Cart-------------*/

.shop-cart-box {
	width: 100%;
	display: inline-block;
	border: 1px solid #ececec;
	margin: 5px 0px;
	padding: 10px 0px;
}
.shop-cart-box .bx {
	margin-bottom: 30px;
}
.button-close {
	display: block;
	padding: 35px 12px;
}
@media (max-width: 991px) {
	.button-close {
		display: block;
		padding: 5px 0px;
	}
}
@media (max-width: 767px) {
	.button-close {
		display: block;
		padding: 35px 0px;
	}
}
.button-close a i {
	color: #fff;
	font-size: 20px;
	font-weight: 600;
	padding: 5px;
	background: #e20b11;
	border-radius: 3px;
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
}
.button-close a i:hover {
	background: #888;
}
.shop-cart-box-img img {
	width: 100%;
	height: auto;
}
.shop-cart-box-info {
	display: block;
	padding: 20px 0px 0px 20px;
}
@media (max-width: 767px) {
	.shop-cart-box-info {
		display: block;
		padding: 20px 0px 0px 0px;
	}
}
.shop-cart-box-info h4 {
	color: #111;
	font-size: 20px;
	font-weight: 500;
}
.shop-cart-box-info span {
	color: #e20b11;
	font-size: 15px;
	font-weight: 400;
}
.shop-cart-box-quantity {
	padding-top: 20px;
}
.shop-cart-box-quantity input {
	max-width: 60px;
	box-shadow: none;
	display: inline-block;
}
.shop-cart-box-quantity h6 {
	display: inline-block;
	font-size: 14px;
	margin-right: 5px;
}
.shop-cart-box-price {
	display: block;
	padding-top: 20px;
}
.shop-cart-box-price h5 {
	color: #666;
	font-size: 25px;
	font-weight: 300;
	margin-right: 15px;
}
@media (max-width: 991px) {
	.shop-cart-box-price h5 {
		font-size: 20px;
	}
}
@media (max-width: 767px) {
	.shop-cart-box-price h5 {
		color: #666;
		font-size: 25px;
		font-weight: 300;
		margin-right: 15px;
	}
}
.shop-cart-info-price {
	padding: 20px;
	border: 1px solid #ececec;
	margin: 5px 0px;
}
.right-info-price li {
	color: #666;
	font-size: 16px;
	font-weight: 500;
	line-height: 160%;
	padding: 10px 0px;
}
.right-info-price li h6 {
	float: right;
	color: #666;
	font-size: 16px;
	font-weight: 500;
}
.total-price {
	margin-top: 15px;
	padding-top: 15px;
	border-top: 1px solid #ececec;
}
.total-price p {
	color: #444;
	font-size: 17px;
	font-weight: 500;
}
.total-price p strong {
	float: right;
	color: #444;
	font-size: 17px;
	font-weight: 500;
}
/*-----------------------------------------35. 404 (Page)-------------------------------------------*/

.error-box {
	text-align: center;
	position: relative;
	width: 100%;
	height: 100%;
	margin: 0 auto;
	text-align: center;
}
.error-box-text {
	z-index: 999;
	position: relative;
	top: -350px;
}
.error-box-text h1 {
	font-size: 200px;
	color: #e20b11;
	line-height: 1;
}
.error-box-text h3 {
	font-size: 40px;
	color: #111;
}
.error-box-text h4 {
	font-size: 20px;
	color: #333;
	margin-top: 20px;
}
.back-box {
	z-index: -999999;
	background: #f9f9f9;
	position: relative;
	top: 150px;
	padding: 50px;
}
.error-box h2 {
	color: rgba(204, 204, 204, 0.2);
	font-size: 250px;
	font-weight: 600;
	letter-spacing: 10px;
	text-transform: uppercase;
}
@media (max-width: 900px) {
	.error-box h2 {
		font-size: 150px;
	}
	.error-box-text {
		top: -200px;
	}
}
@media (max-width: 767px) {
	.error-box {
		padding: 30px;
	}
	.back-box {
		display: none;
	}
	.error-box-text {
		top: 50px;
	}
	.error-box-text h1 {
		font-size: 100px;
	}
}
/*-----------------------------------------36. Contact-------------------------------------------*/

/*----------- Style 1-------------*/

.contact-form input {
	font-weight: 500 !important;
	border-radius: 5px;
	font-size: 13px;
	border: 1px solid #f6f7f8;
	background: #f6f7f8;
	color: #777 !important;
	outline: none;
	width: 100%;
	padding: 14px;
	margin: 10px 0px;
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
}
.contact-form textarea {
	font-weight: 500 !important;
	border-radius: 5px;
	font-size: 13px;
	border: 1px solid #f6f7f8;
	background: #f6f7f8;
	color: #777 !important;
	outline: none;
	width: 100%;
	padding: 14px;
	margin: 10px 0px;
	margin-bottom: 5px;
	min-height: 200px;
	overflow: hidden;
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
}
.contact-form input:focus,
.contact-form textarea:focus {
	background: #fff;
	border-color: #ececec;
}
.contact-form button {
	display: inline-block;
	background: #e6292e;
	color: #fff;
	font-size: 14px;
	width: 100%;
	font-weight: 500;
	border-radius: 10px;
	padding: 15px 30px 15px 30px;
	-webkit-transition: all .4s ease;
	-moz-transition: all .4s ease;
	transition: all .4s ease;
	margin-top: 15px;
}
.contact-form button:hover {
	background: #251dc3;
}
/*INFO*/

.contact-info-box {
	padding: 0px 15px;
}
.contact-info-section {
	border-bottom: 1px solid #ececec;
	padding: 25px 0px;
	margin-bottom: 0px;
	-webkit-transition-duration: .5s;
	transition-duration: .5s;
}
.contact-info-section i {
	width: 60px;
	height: 60px;
	padding-top: 17px;
	border-radius: 50%;
	background: #e20b11;
	color: #fff;
	font-size: 25px;
	margin-top: 5px;
}
.contact-info-section h4 {
	color: #111;
	font-size: 18px;
	font-weight: 500;
	margin-bottom: 10px;
}
.contact-info-section p {
	color: #999;
	font-size: 15px;
	font-weight: 400;
}
#map {
	height: 400px;
	width: 100%;
}
/*----------- Style 2-------------*/

.contact-box {
	text-align: center;
	background: #fff;
	border: 1px solid #ececec;
	border-radius: 2px;
	-webkit-box-shadow: 0 20px 80px rgba(0, 0, 0, 0.12);
	box-shadow: 0 20px 80px rgba(0, 0, 0, 0.12);
	-webkit-transition-duration: .3s;
	margin: 10px 0px 10px 0px;
	padding: 20px 0px 20px 0px;
}
.contact-box i {
	color: #e20b11;
	font-size: 35px;
	margin: 10px 0px 20px 0px;
}
.contact-box h4 {
	color: #111;
	font-size: 18px;
	font-weight: 500;
	margin: 0px 0px 5px 0px;
}
.contact-box span {
	color: #999;
	font-size: 14px;
}
/*----------- Style 3-------------*/

.contact-country-one {
	background-size: cover;
	width: 100%;
	padding: 120px 70px 50px 70px;
}
.contact-country-one i {
	color: #fff;
	font-size: 60px;
}
.contact-country-one h4 {
	color: #fff;
	font-size: 25px;
	font-weight: 500;
	margin-top: 20px;
}
.contact-country {
	margin-top: 20px;
}
.contact-country li {
	color: #fff;
	font-size: 15px;
	padding: 10px 0px;
}
.contact-country li i {
	color: #fff;
	font-size: 15px;
	margin-right: 15px;
}
@media (max-width: 767px) {
	.contact-country-one {
		padding: 40px 20px;
	}
}
/*-----------Styles 4-------------*/

.contact-data-box-icon {
	border-radius: 10%;
	border: 2px solid #0d04bd;
	background:#0d04bd;
	width: 80px;
	height: 80px;
	padding: 19px;
	margin: 8px 0px 0px 10px;
	-webkit-transition-duration: 0.6s;
	transition-duration: 0.6s;
}
.contact-data-box:hover .contact-data-box-icon {
	background: #fff;
}
.contact-data-box-icon i {
	color: #fff;
	font-size: 30px;
	padding: 3px 0px 0px 6px;
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
}
.contact-data-box:hover .contact-data-box-icon i {
	color: #333;
}
.contact-data-box-text {
	padding-left: 15px;
}
.contact-data-box-text h4 {
	color:#0e05bf;
	font-size: 19px;
	font-weight: 400;
	line-height: 160%;
	letter-spacing: 1px;
}
.contact-data-box-text h6 {
	color: #777;
	font-size: 15px;
	font-weight: 400;
	line-height: 160%;
	margin-top: 5px;
}
/*-----------------------------------------37. Tabs-------------------------------------------*/

/*----------- Default Tabs-------------*/

.default-tabs .tabs {
	position: relative;
}
.default-tabs .tabs .tab-menu li {
	background: #f7f7f7;
	display: inline-block;
	text-align: center;
}
.default-tabs .tabs .tab-menu li a {
	color: #111;
	display: block;
	font-size: 15px;
	font-weight: 500;
	padding: 18px 24px;
	text-decoration: none;
}
.default-tabs .tabs .tab-menu li a i {
	color: #e20b11;
	display: inline-block;
	font-size: 15px;
	margin-right: 10px;
	text-decoration: none;
}
.default-tabs .tabs .tab-menu li:hover a,
.default-tabs .tabs .tabs li:hover i {
	color: #e20b11;
}
.default-tabs .tab-menu .active-tab a,
.default-tabs .active-tab a i {
	color: #e20b11 !important;
}
.default-tabs .tab-menu {
	margin-bottom: 4px;
}
@media screen and (max-width: 1200px) {
	.default-tabs .tabs .tab-menu li {
		background: #f7f7f7;
		display: inline-block;
		text-align: center;
		width: auto;
	}
	.default-tabs .tabs .tab-menu li a {
		color: #111;
		display: block;
		font-size: 15px;
		font-weight: 500;
		padding: 18px 24px;
		text-decoration: none;
	}
}
@media screen and (max-width: 767px) {
	.default-tabs .tabs .tab-menu li {
		display: block;
		width: 100%;
		text-align: center;
		margin-bottom: 4px;
	}
	.default-tabs .tab-menu .tabs li a {
		display: block;
		padding: 18px 14px;
	}
	.default-tabs .tab-menu .tabs li i {
		font-size: 25px;
	}
}
.tab-body {
	display: block;
	position: relative;
	padding: 25px;
	background: #f7f7f7 !important;
}
.tab-body h2 {
	font-size: 30px;
	color: #111;
	font-weight: 300;
	margin-bottom: 10px;
}
.tab-body h3 {
	font-size: 25px;
	color: #111;
	font-weight: 500;
	margin-bottom: 10px;
}
.tab-body h4 {
	font-size: 20px;
	color: #111;
	font-weight: 500;
	margin-bottom: 10px;
}
.tab-body h5 {
	font-size: 15px;
	color: #111;
	font-weight: 500;
	margin-bottom: 10px;
}
.tab-body h2:after,
.tab-body h3:after,
.tab-body h4:after,
.tab-body h5:after {
	content: '';
	display: block;
	width: 100px;
	height: 2px;
	background: #e20b11;
	margin-top: 10px;
	margin-bottom: 10px;
}
.tab-body p {
	font-size: 15px;
	color: #999;
	line-height: 1.7;
	font-weight: 400;
	margin: 5px;
}
/*----------- Icon Tabs-------------*/

.big-icon-tabs .tabs {
	position: relative;
}
.big-icon-tabs .tabs .tab-menu li {
	display: inline-block;
	text-align: center;
	margin-right: 5px;
}
.big-icon-tabs .tabs .tab-menu li:last-child {
	margin-right: 0px;
}
.big-icon-tabs .tabs .tab-menu li a {
	background: #ececec;
	color: #111;
	display: block;
	font-size: 15px;
	font-weight: 500;
	padding: 18px 24px;
	text-decoration: none;
	border-top: 2px solid #ececec;
}
.big-icon-tabs .tabs .tab-menu li a i {
	color: #e20b11;
	display: block;
	font-size: 40px;
	margin-bottom: 10px;
	text-decoration: none;
}
.big-icon-tabs .tabs .tab-menu li:hover a,
.big-icon-tabs .tabs .tabs li:hover i {
	color: #e20b11;
}
.big-icon-tabs .tab-menu .active-tab a,
.big-icon-tabs .active-tab a i {
	border-color: #e20b11 !important;
}
.big-icon-tabs .tab-menu .active-tab a {
	background: #f7f7f7 !important;
}
@media screen and (max-width: 1200px) {
	.big-icon-tabs .tabs .tab-menu li {
		background: #f7f7f7;
		display: inline-block;
		text-align: center;
		width: auto;
	}
	.big-icon-tabs .tabs .tab-menu li a {
		color: #111;
		display: block;
		font-size: 15px;
		font-weight: 500;
		padding: 18px 24px;
		text-decoration: none;
	}
}
@media screen and (max-width: 767px) {
	.big-icon-tabs .tabs .tab-menu li {
		display: block;
		width: 100%;
		text-align: center;
		margin-bottom: 4px;
	}
	.big-icon-tabs .tab-menu .tabs li a {
		display: block;
	}
	.big-icon-tabs .tab-menu .tabs li i {
		font-size: 25px;
	}
}
/*-----------------------------------------38. Owl Carousel-------------------------------------------*/

.custom-dot {
	display: inline-block;
	z-index: 1;
	margin-top: 30px;
}
.custom-dot span {
	width: 12px;
	height: 12px;
	margin: 5px 7px;
	background: #cecece;
	cursor: pointer;
	display: block;
	border: 1px solid #fff;
	-webkit-backface-visibility: visible;
	border-radius: 30px;
	-webkit-transition-duration: .3s;
	transition-duration: .3s;
}
.custom-dot span:hover {
	background: #aaa;
	-webkit-backface-visibility: visible;
	border-radius: 30px;
}
.custom-dot.active span {
	width: 12px;
	height: 12px;
	background: #e20b11;
	-webkit-transition-duration: .3s;
	transition-duration: .3s;
}
.owl-nav i {
	color: #111;
	font-size: 20px;
	padding-top: 10px;
}
.owl-nav .owl-prev {
	color: #e20b11;
	font-size: 43px;
	left: 0%;
	text-align: center;
	-webkit-transition: all 0.4s ease 0s;
	transition: all 0.4s ease 0s;
	width: 50px;
	height: 50px;
	background: #fff !important;
	-webkit-box-shadow: 0px 5px 28px 0px rgba(50, 50, 50, 0.1);
	-moz-box-shadow: 0px 5px 28px 0px rgba(50, 50, 50, 0.1);
	box-shadow: 0px 5px 28px 0px rgba(50, 50, 50, 0.1);
}
.owl-nav .owl-next {
	color: #e20b11;
	font-size: 43px;
	text-align: center;
	-webkit-transition: all 0.4s ease 0s;
	transition: all 0.4s ease 0s;
	width: 50px;
	height: 50px;
	background: #fff !important;
	-webkit-box-shadow: 0px 5px 28px 0px rgba(50, 50, 50, 0.1);
	-moz-box-shadow: 0px 5px 28px 0px rgba(50, 50, 50, 0.1);
	box-shadow: 0px 5px 28px 0px rgba(50, 50, 50, 0.1);
}
/*-----------------------------------------39. Pie Charts-------------------------------------------*/

.pie-chart {
	margin: 20px 0px;
}
.pie-chart .chart {
	position: relative;
	display: block;
	width: 120px;
	color: #111;
	text-align: center;
	border-radius: 50%;
	margin: auto;
}
.pie-chart-lg .chart {
	width: 190px;
}
.pie-chart-md .chart {
	width: 150px;
}
.pie-chart-sm .chart {
	width: 130px;
}
.pie-chart .chart canvas {
	position: absolute;
	top: 0;
	left: 0;
}
.pie-chart .chart-percent {
	margin: 0 0 15px 0
}
.pie-chart .percent {
	font-size: 17px;
	font-weight: 600;
	display: inline-block;
	z-index: 2;
}
.pie-chart-md .percent {
	font-size: 18px;
	font-weight: 600;
	display: inline-block;
	z-index: 2;
}
.percent:after {
	content: '%';
}
.pie-chart h4 {
	font-size: 14px;
	font-weight: 600;
	text-align: center;
	margin-top: 15px;
}
.pie-chart-md h4 {
	font-size: 18px;
	font-weight: 600;
	text-align: center;
	margin-top: 17px;
}
.pie-chart h5 {
	color: #999;
	font-size: 12px;
	font-weight: 500;
	text-align: center;
	margin-top: 15px;
}
.pie-chart-md h5 {
	color: #999;
	font-size: 13px;
	font-weight: 500;
	text-align: center;
	margin-top: 7px;
}
.pie-chart-lg h4 {
	font-size: 19px;
	font-weight: 600;
	text-align: center;
	margin-top: 17px;
}
.pie-chart-lg h5 {
	color: #999;
	font-size: 13px;
	font-weight: 500;
	text-align: center;
	margin-top: 7px;
}
.pie-chart-lg .percent {
	font-size: 22px;
	font-weight: 600;
	display: inline-block;
	z-index: 2;
}
/*-----------------------------------------40. Chart JS-------------------------------------------*/

.chartjs-tooltip {
	opacity: 1;
	position: absolute;
	background: rgba(0, 0, 0, .7);
	color: white;
	border-radius: 3px;
	-webkit-transition: all .1s ease;
	transition: all .1s ease;
	pointer-events: none;
	-webkit-transform: translate(-50%, 0);
	transform: translate(-50%, 0);
	padding: 4px;
}
.chartjs-tooltip-key {
	display: inline-block;
	width: 10px;
	height: 10px;
}