/*==============================================TABLE OF CONTENT==============================================1. General2. Align Holders3. Modals4. Margins, Paddings5. Overflow6. Clearfix7. Visibility==============================================[END] TABLE OF CONTENT==============================================*/
/*-----------------------------------------1. General-------------------------------------------*/
html,
body {
    font-family: 'Poppins';
    height: 100%;
    margin: 0;
    padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Poppins';
    color: #111;
    line-height: 1.3;
    margin: 0px;
}

h1,
h2,
h3,
h4 {
    font-weight: 700;
}

h5,
h6 {
    font-weight: 600;
}

p {
    font-size: 14px;
    color: #999;
    line-height: 1.7;
    margin-bottom: 0px;
}

img {
    max-width: 100%;
    height: auto;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
}

a {
    color: #111;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
}

a:active,
a:hover,
a:focus {
    color: #111;
    outline: 0 none;
    text-decoration: none;
}

button {
    background: none;
    outline: 0 none;
    border: none;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
}

iframe {
    border: 0px;
}

input,
textarea {
    outline: none;
    max-width: 100%;
}

ul {
    padding-left: 0px;
    margin-bottom: 0px;
}

li {
    list-style-type: none;
}

::-moz-selection {
    background: #111;
    color: #fff;
    text-shadow: none;
}

::selection {
    background: #111;
    color: #fff;
    text-shadow: none;
}

/*-----------------------------------------2. Align Holders-------------------------------------------*/
.left-holder {
    text-align: left !important;
}

.right-holder {
    text-align: right !important;
}

.center-holder {
    text-align: center !important;
}

.right-holder-md {
    text-align: right;
}

@media (max-width: 767px) {
    .right-holder-md {
        text-align: center;
    }
}

/*----------------------------------------- 3. Modals-------------------------------------------*/
.modal {
    text-align: center;
    padding: 0 !important;
    border-radius: 10px;
    z-index: 999999;
}

.modal:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -4px;
    border-radius: 10px;
    z-index: 999999;
}

.modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
    z-index: 999999;
}

/*----------------------------------------- 4. Margins, Paddings-------------------------------------------*/
/*------------------ Margin Left--------------------*/
.ml-15 {
    margin-left: 15px;
}

/*------------------ Margin top--------------------*/
.mt-0 {
    margin-top: 0 !important
}

.mt-5 {
    margin-top: 5px !important;
}

.mt-10 {
    margin-top: 10px
}

.mt-15 {
    margin-top: 15px
}

.mt-20 {
    margin-top: 20px
}

.mt-25 {
    margin-top: 25px
}

.mt-30 {
    margin-top: 30px
}

.mt-35 {
    margin-top: 35px
}

.mt-40 {
    margin-top: 40px
}

.mt-45 {
    margin-top: 45px
}

.mt-50 {
    margin-top: 50px
}

.mt-55 {
    margin-top: 55px
}

.mt-60 {
    margin-top: 60px
}

.mt-65 {
    margin-top: 65px
}

.mt-70 {
    margin-top: 70px
}

.mt-75 {
    margin-top: 75px
}

.mt-80 {
    margin-top: 80px
}

.mt-85 {
    margin-top: 85px
}

.mt-90 {
    margin-top: 90px
}

.mt-95 {
    margin-top: 95px
}

.mt-100 {
    margin-top: 100px
}

.mt-105 {
    margin-top: 105px
}

.mt-110 {
    margin-top: 110px
}

.mt-115 {
    margin-top: 115px
}

.mt-120 {
    margin-top: 120px
}

.mt-125 {
    margin-top: 125px
}

.mt-130 {
    margin-top: 130px
}

.mt-135 {
    margin-top: 135px
}

.mt-140 {
    margin-top: 140px
}

.mt-145 {
    margin-top: 145px
}

.mt-150 {
    margin-top: 150px
}

.mt-15-xs {
    margin-top: 0px;
}

@media screen and (max-width:767px) {
    .mt-15-xs {
        margin-top: 15px;
    }
}

/*------------------ Margin bottom--------------------*/
.mb-0 {
    margin-bottom: 0
}

.mb-10 {
    margin-bottom: 10px
}

.mb-15 {
    margin-bottom: 15px
}

.mb-20 {
    margin-bottom: 20px
}

.mb-25 {
    margin-bottom: 25px
}

.mb-30 {
    margin-bottom: 30px
}

.mb-35 {
    margin-bottom: 35px
}

.mb-40 {
    margin-bottom: 40px
}

.mb-45 {
    margin-bottom: 45px
}

.mb-50 {
    margin-bottom: 50px
}

.mb-55 {
    margin-bottom: 55px
}

.mb-60 {
    margin-bottom: 60px
}

.mb-65 {
    margin-bottom: 65px
}

.mb-70 {
    margin-bottom: 70px
}

.mb-75 {
    margin-bottom: 75px
}

.mb-80 {
    margin-bottom: 80px
}

.mb-85 {
    margin-bottom: 85px
}

.mb-90 {
    margin-bottom: 90px
}

.mb-95 {
    margin-bottom: 95px
}

.mb-100 {
    margin-bottom: 100px
}

.mb-105 {
    margin-bottom: 105px
}

.mb-110 {
    margin-bottom: 110px
}

.mb-115 {
    margin-bottom: 115px
}

.mb-120 {
    margin-bottom: 120px
}

.mb-125 {
    margin-bottom: 125px
}

.mb-130 {
    margin-bottom: 130px
}

.mb-135 {
    margin-bottom: 135px
}

.mb-140 {
    margin-bottom: 140px
}

.mb-145 {
    margin-bottom: 145px
}

.mb-150 {
    margin-bottom: 150px
}

.mb-15-xs {
    margin-bottom: 0px;
}

@media screen and (max-width:767px) {
    .mb-15-xs {
        margin-bottom: 15px;
    }
}

@media screen and (max-width:767px) {
    .mb-0-xs {
        margin-bottom: 0px;
    }
}

@media screen and (max-width:767px) {
    .mt-0-xs {
        margin-top: 0px;
    }
}

/*------------------ Margin right--------------------*/
.mr-20-md {
    margin-right: 20px;
}

@media screen and (max-width:767px) {
    .mr-30-md {
        margin-right: 0px;
    }
}

/*------------------ Paddings--------------------*/
.no-padding {
    padding: 0px !important;
}

.pb-0 {
    padding-bottom: 0px !important;
}

.pt-0 {
    padding-top: 0px !important;
}

.pl-0 {
    padding-left: 0px;
}

.pl-0-md {
    padding-left: 0px;
}

@media screen and (max-width:991px) {
    .pl-0-md {
        padding-left: 15px;
    }
}

.pr-0 {
    padding-right: 0px;
}

.pr-0-md {
    padding-right: 0px;
}

@media screen and (max-width:991px) {
    .pr-0-md {
        padding-right: 15px;
    }
}

.pl-15-md {
    padding-left: 15px;
}

@media screen and (max-width:991px) {
    .pl-15-md {
        padding-left: 0px;
    }
}

.pl-30-md {
    padding-left: 30px;
}

@media screen and (max-width:991px) {
    .pl-30-md {
        padding-left: 0px;
    }
}

.pr-15-md {
    padding-right: 15px;
}

@media screen and (max-width:991px) {
    .pr-15-md {
        padding-right: 0px;
    }
}

.pr-30-md {
    padding-right: 30px;
}

@media screen and (max-width:991px) {
    .pr-30-md {
        padding-right: 0px;
    }
}

.pl-45-md {
    padding-left: 45px;
}

@media screen and (max-width:991px) {
    .pl-45-md {
        padding-left: 0px;
    }
}

.pl-30-md {
    padding-left: 30px;
}

@media screen and (max-width:991px) {
    .pl-30-md {
        padding-left: 0px;
    }
}

.padding-10-perc {
    padding: 10%;
}

@media screen and (max-width:776px) {
    .padding-10-perc {
        padding: 70px 15px;
    }
}

/*----------------------------------------- 5. Overflow-------------------------------------------*/
.overflow-hidden {
    overflow: hidden;
}

.display-b {
    display: block;
}

/*----------------------------------------- 6. Clearfix-------------------------------------------*/
.clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}

.clearfix {
    display: inline-block;
}

/* start commented backslash hack \*/
* html .clearfix {
    height: 1%;
}

.clearfix {
    display: block;
}

/* close commented backslash hack */
/*----------------------------------------- 7. Visibility-------------------------------------------*/
.invisible {
    visibility: hidden !important;
}

.hidden-xs-up {
    display: none !important;
}

@media (max-width: 575px) {
    .hidden-xs-down {
        display: none !important;
    }
}

@media (min-width: 576px) {
    .hidden-sm-up {
        display: none !important;
    }
}

@media (max-width: 767px) {
    .hidden-sm-down {
        display: none !important;
    }
}

@media (min-width: 768px) {
    .hidden-md-up {
        display: none !important;
    }
}

@media (max-width: 991px) {
    .hidden-md-down {
        display: none !important;
    }
}

@media (min-width: 992px) {
    .hidden-lg-up {
        display: none !important;
    }
}

@media (max-width: 1199px) {
    .hidden-lg-down {
        display: none !important;
    }
}

@media (min-width: 1200px) {
    .hidden-xl-up {
        display: none !important;
    }
}

.hidden-xl-down {
    display: none !important;
}

@media (max-width: 767px) {
    .reverse-xs {
        flex-direction: column-reverse;
    }
}